import React, { useCallback, useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Box,
  Button,
  Typography,
  Stack,
  ListItemIcon,
  Chip,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { rarityIcons, inkColorMap } from './constants';
import Loading from '../components/Loading';

const InventoryCardList = ({ cards, openListingModal, convertPrice, currentUser }) => {
  const [listings, setListings] = useState({});
  const MAX_CHIPS_DISPLAY = 2;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchListings = async (card) => {
    try {
      const listingsSnapshot = await getDocs(
        collection(db, 'sets', card.setId, 'cards', card.docId, 'listings')
      );
      const listingsData = listingsSnapshot.docs.reduce((acc, doc) => {
        // Only include listings that match the current user's UID
        if (doc.id === currentUser.uid) {
          const data = doc.data();
          acc[doc.id] = Object.entries(data).map(([condition, listing]) => ({
            condition,
            ...listing,
          }));
        }
        return acc;
      }, {});
      setListings((prevListings) => ({
        ...prevListings,
        [card.docId]: listingsData,
      }));
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
  };  

  useEffect(() => {
    cards.forEach((card) => {
      fetchListings(card);
    });
  }, [cards]);

  const renderCard = useCallback(
    (card) => (
      <ListItem key={card.docId} sx={{ border: "2px solid #0C0A5A" }}>
        <Stack direction={isMobile ? "column" : "row"} spacing={2} sx={{ width: "100%" }}>
          {/* Top Section: Avatar, Icons, Text */}
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <ListItemAvatar sx={{ width: 100 }}>
              <Avatar
                className={card.type === "Foil" ? "foil-avatar" : ""}
                variant="square"
                src={card.image_uris?.digital?.small || card.image_uris?.digital?.large}
                sx={{
                  height: "20%",
                  width: "80%",
                  objectFit: "contain",
                  border: `4px solid ${inkColorMap[card.ink] || "#F2D18B"}`,
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "fallback_image_url";
                }}
              />
              {card.type === "Foil" && (
                <Avatar
                  variant="square"
                  sx={{
                    width: 76,
                    height: 10,
                    bgcolor: "#ffd700",
                    border: "2px solid #000000",
                    color: "#000000",
                    bottom: 18,
                    left: 4,
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: "10px" }}>
                    COLD FOIL
                  </Typography>
                </Avatar>
              )}
            </ListItemAvatar>
            <Stack spacing={1}>
              <ListItemIcon>
                <Avatar variant="square" sx={{ bgcolor: "#0C0A5A" }}>
                  {card.collector_number}
                </Avatar>
              </ListItemIcon>
              <ListItemIcon>
                <Avatar variant="square" sx={{ bgcolor: "#0C0A5A" }}>
                  <img
                    src={rarityIcons[card.rarity]}
                    alt={card.rarity}
                    style={{ width: 24, height: 24 }}
                  />
                </Avatar>
              </ListItemIcon>
            </Stack>
            <ListItemText
              primary={<Typography variant="body1">{card.name}</Typography>}
              secondary={
                card.version && (
                  <Typography variant="body2" color="textSecondary">
                    {card.version}
                  </Typography>
                )
              }
            />
          </Box>

          {/* Bottom Section: Chips on Left, TCGP and Button on Right */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* Left: Chips */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 1,
                width: "50%",
              }}
            >
              {listings[card.docId] ? (
                Object.values(listings[card.docId]).flatMap((userListings, userIndex) => {
                  const limitedListings = userListings.slice(0, MAX_CHIPS_DISPLAY);
                  const remainingListings = userListings.slice(MAX_CHIPS_DISPLAY);

                  return (
                    <React.Fragment key={`${card.docId}-user-${userIndex}`}>
                      {limitedListings.map((listing, listingIndex) => (
                        <Chip
                          key={`${card.docId}-${listing.condition}-${listingIndex}`}
                          label={`${listing.condition} - Qty: ${listing.quantity}`}
                          sx={{
                            borderRadius: "4px",
                            bgcolor: "secondary.main",
                            color: "white",
                            width: "90%",
                          }}
                        />
                      ))}
                      {remainingListings.length > 0 && (
                        <Tooltip
                          title={
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 1,
                              }}
                            >
                              {remainingListings.map((listing, index) => (
                                <Chip
                                  key={`${card.docId}-${listing.condition}-${index}`}
                                  label={`${listing.condition} - Qty: ${listing.quantity}`}
                                  sx={{
                                    bgcolor: "secondary.main",
                                    color: "white",
                                    width: "90%",
                                  }}
                                />
                              ))}
                            </Box>
                          }
                          placement="top"
                          arrow
                        >
                          <Chip
                            label={`+${remainingListings.length} more`}
                            sx={{
                              borderRadius: "4px",
                              bgcolor: "grey.500",
                              color: "white",
                              width: "90%",
                            }}
                          />
                        </Tooltip>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <Loading />
              )}
            </Box>

            {/* Right: TCGP Price and Add/Update Button */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 1,
                width: "50%",
              }}
            >
              <Chip
                clickable
                component="a"
                href={`https://www.tcgplayer.com/product/${card.tcgplayer_id}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  height: "auto",
                  width: "90%",
                  borderRadius: "4px",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                  },
                  bgcolor: "green",
                  color: "white",
                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span>TCGP Price</span>
                    {convertPrice(card.price || 0)}
                  </Box>
                }
              />

              <Button
                variant="contained"
                color="primary"
                onClick={() => openListingModal(card)}
                sx={{
                  width: "90%",
                }}
              >
                Add/Update Listing
              </Button>
            </Box>
          </Box>
        </Stack>
      </ListItem>
    ),
    [openListingModal, listings, convertPrice, isMobile]
  );

  return <List>{cards.map(renderCard)}</List>;
};

export default React.memo(InventoryCardList);
