import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, updateDoc, deleteField } from 'firebase/firestore';
import {
  Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableRow, TableHead, CircularProgress, Box, Typography, TextField, Button, IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const UserListings = ({ userId, open, onClose }) => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetchUserListings();
    }
  }, [open]);

  const fetchUserListings = async () => {
    try {
      setLoading(true);
      const allListings = [];

      // Fetch all sets in parallel
      const setsSnapshot = await getDocs(collection(db, 'sets'));
      const setsData = setsSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name, // Get set name
      }));

      // Use Promise.all to fetch listings for all cards across all sets in parallel
      const listingsPromises = setsData.map(async (set) => {
        const cardsSnapshot = await getDocs(collection(db, 'sets', set.id, 'cards'));

        const cardListingsPromises = cardsSnapshot.docs.map(async (cardDoc) => {
          const cardId = cardDoc.id;
          const cardData = cardDoc.data();
          const cardName = cardData.name; // Get card name
          const cardNumber = cardData.collector_number; // Get card number

          const listingsSnapshot = await getDocs(collection(db, 'sets', set.id, 'cards', cardId, 'listings'));
          listingsSnapshot.docs.forEach((listingDoc) => {
            if (listingDoc.id === userId) {
              const data = listingDoc.data();
              Object.entries(data).forEach(([condition, listing]) => {
                allListings.push({
                  ...listing,
                  condition,
                  setName: set.name, // Add set name to the listing
                  cardName,          // Add card name
                  cardNumber,        // Add card number
                  listingRef: listingDoc.ref,
                });
              });
            }
          });
        });

        await Promise.all(cardListingsPromises); // Wait for all card listings to be fetched
      });

      await Promise.all(listingsPromises); // Wait for all sets to finish processing

      setListings(allListings);
    } catch (error) {
      console.error('Error fetching user listings:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (index, newQuantity) => {
    setListings((prevListings) => {
      const updatedListings = [...prevListings];
      updatedListings[index].quantity = newQuantity;
      return updatedListings;
    });
  };

  const handleSave = async (index) => {
    const listing = listings[index];
    try {
      const listingRef = listing.listingRef;
      await updateDoc(listingRef, {
        [listing.condition + '.quantity']: listing.quantity,
      });
      alert('Listing updated successfully');
    } catch (error) {
      console.error('Error updating listing:', error);
    }
  };

  const handleDelete = async (index) => {
    const listing = listings[index];
    try {
      const listingRef = listing.listingRef;
      await updateDoc(listingRef, {
        [listing.condition]: deleteField(),
      });

      // Remove listing from state
      setListings((prevListings) => prevListings.filter((_, i) => i !== index));
      alert('Listing deleted successfully');
    } catch (error) {
      console.error('Error deleting listing:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>User Listings</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : listings.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Set Name</TableCell>
                <TableCell>Card Name</TableCell>
                <TableCell>Card Number</TableCell>
                <TableCell>Condition</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listings.map((listing, index) => (
                <TableRow key={index}>
                  <TableCell>{listing.setName}</TableCell>
                  <TableCell>{listing.cardName}</TableCell>
                  <TableCell>{listing.cardNumber}</TableCell>
                  <TableCell>{listing.condition}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={listing.quantity}
                      onChange={(e) => handleQuantityChange(index, e.target.value)}
                      inputProps={{ min: 1 }}
                    />
                  </TableCell>
                  <TableCell>{listing.price}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleSave(index)} sx={{ marginRight: 1 }}>
                      Save
                    </Button>
                    <IconButton color="secondary" onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="body1" align="center">
            No listings found for this user.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserListings;
