import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, Dialog, DialogTitle, DialogContent, useMediaQuery } from '@mui/material';
import { collection, getDocs, doc, setDoc, getDoc } from 'firebase/firestore'; 
import { db } from '../firebase';
import { useAuth } from '../auth';
import { useAppBarContext } from '../components/AppBarContext';
import Loading from '../components/Loading';
import WishlistCardList from './WishlistCardList'; 
import WishlistFacebookListingCreator from './WishlistFacebookListingCreator';
import WishlistMarketplaceListings from './WishlistMarketplaceListings';

const WishList = () => {
  const { currentUser } = useAuth();
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openListingsDialog, setOpenListingsDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const isMobile = useMediaQuery('(max-width:600px)'); // Mobile breakpoint

  useEffect(() => {
    const fetchWishlist = async () => {
      setLoading(true);
      try {
        const wishlist = [];
        const collectionPath = `users/${currentUser.uid}/mycollection`;
        const setsSnapshot = await getDocs(collection(db, collectionPath));

        for (let setDoc of setsSnapshot.docs) {
          const setId = setDoc.id;
          const cardsSnapshot = await getDocs(collection(db, collectionPath, setId, 'cards'));
          cardsSnapshot.docs.forEach((cardDoc) => {
            const cardData = cardDoc.data();
            if (cardData.inWishlist) {
              wishlist.push({
                docId: cardDoc.id,
                setId: setId,
                ...cardData,
              });
            }
          });
        }

        setWishlistItems(wishlist);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching wishlist items:', error);
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchWishlist();
    }
  }, [currentUser]);

  const handleAddToList = async (card, listType) => {
    try {
      const collectionPath = `users/${currentUser.uid}/mycollection/${card.setId}/cards`;
      const cardRef = doc(db, collectionPath, card.docId);
      const updatedCardData = { ...card, inWishlist: listType === 'wishlist' ? !card.inWishlist : card.inWishlist };

      await setDoc(cardRef, updatedCardData);
      setWishlistItems((prevItems) => prevItems.filter((item) => item.docId !== card.docId));
    } catch (error) {
      console.error('Error handling list update:', error);
    }
  };

  const handleUpdateQuantity = async (docId, newQuantity) => {
    try {
      const collectionPath = `users/${currentUser.uid}/mycollection/${docId}`;
      const cardRef = doc(db, collectionPath, docId);

      await setDoc(cardRef, { quantity: newQuantity }, { merge: true });
      setWishlistItems((prevItems) =>
        prevItems.map((item) => (item.docId === docId ? { ...item, quantity: newQuantity } : item))
      );
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  };

  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${selectedCountry} ${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  };

  const addToCart = async (listing, price, cardId, setId) => {
    console.log('Adding to cart:', { listing });
    const userId = currentUser.uid;
    const cartRef = doc(db, 'carts', userId);
    const cartDoc = await getDoc(cartRef);
    let cartData = cartDoc.exists() ? cartDoc.data() : { items: [] };

    if (!setId || !cardId) {
      console.error('Missing setId or cardId:', { setId, cardId });
      return;
    }

    try {
      const cardRef = doc(db, `sets/${setId}/cards/${cardId}`);
      const cardDoc = await getDoc(cardRef);

      if (!cardDoc.exists()) {
        console.error('Card not found!');
        return;
      }

      const card = cardDoc.data(); 

      const existingItemIndex = cartData.items.findIndex(item => item.docName === listing.docName && item.type === 'listing' && item.listingId === listing.docName);

      if (existingItemIndex > -1) {
        cartData.items[existingItemIndex].quantity += 1;
      } else {
        const newItem = {
          type: 'listing',
          setId: setId,
          docName: cardId,
          listingId: listing.userId,
          condition: listing.condition,
          quantity: 1,
          price: price,
          name: card.name || 'Unknown Name',
          ink: card.ink || '',
          rarity: card.rarity || '',
          version: card.version || '',
          collector_number: card.collector_number || '',
          sellerId: listing.userId || 'Unknown Seller',
        };

        Object.keys(newItem).forEach(key => {
          if (newItem[key] === undefined) {
            delete newItem[key];
          }
        });

        cartData.items.push(newItem);
      }

      await setDoc(cartRef, cartData);
      alert('Item added to cart!');
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenListingsDialog = () => {
    setOpenListingsDialog(true);
  };

  const handleCloseListingsDialog = () => {
    setOpenListingsDialog(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container maxWidth={isMobile ? 'xs' : 'md'}>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'center' : 'space-between'}
        alignItems={isMobile ? 'stretch' : 'center'}
        mb={2}
      >
        <Typography variant={isMobile ? 'h5' : 'h4'} textAlign={isMobile ? 'center' : 'left'}>
          My Wishlist
        </Typography>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 0}
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth={isMobile}
            onClick={handleOpenDialog}
          >
            Create Facebook Post
          </Button>
          <Button
            variant="contained"
            color="secondary"
            fullWidth={isMobile}
            onClick={handleOpenListingsDialog}
            sx={{ ml: isMobile ? 0 : 2, mt: isMobile ? 1 : 0 }}
          >
            Search Marketplace Listings
          </Button>
        </Box>
      </Box>

      {wishlistItems.length > 0 ? (
        <WishlistCardList
          items={wishlistItems}
          convertPrice={convertPrice}
          handleAddToList={handleAddToList}
          handleUpdateQuantity={handleUpdateQuantity}
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={isMobile ? '50vh' : '100vh'}
        >
          <Typography variant="h6">Your wishlist is empty.</Typography>
        </Box>
      )}

      {/* Facebook Listing Creator Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create Facebook Post from Wishlist</DialogTitle>
        <DialogContent>
          <WishlistFacebookListingCreator
            wishlistItems={wishlistItems}
            currentUser={currentUser}
            convertPrice={convertPrice}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </DialogContent>
      </Dialog>

      {/* Marketplace Listings Dialog */}
      <Dialog
        open={openListingsDialog}
        onClose={handleCloseListingsDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <WishlistMarketplaceListings
            wishlistItems={wishlistItems}
            convertPrice={convertPrice}
            addToCart={addToCart}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default WishList;
