import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, Box } from '@mui/material';
import { Rating } from '@mui/lab';
import { db } from '../firebase';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

const RateSellerDialog = ({ open, onClose, sale }) => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const handleRateSeller = async () => {
    if (sale && sale.sellerId) {
      const sellerRef = doc(db, 'users', sale.sellerId);
      try {
        await updateDoc(sellerRef, {
          reviews: arrayUnion({
            rating,
            comment,
            purchaseId: sale.id,
          }),
        });
        onClose();
      } catch (error) {
        console.error('Error updating reviews:', error);
        alert('Error updating reviews: ' + error.message);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Rate Seller</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <Typography variant="h6">Rate your experience</Typography>
          <Rating
            name="seller-rating"
            value={rating}
            onChange={(event, newValue) => setRating(newValue)}
          />
        </Box>
        <TextField
          label="Comment"
          fullWidth
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          variant="outlined"
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleRateSeller} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RateSellerDialog;
