import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Box,
  Typography,
  Avatar,
  Stack,
  CardActionArea,
  Popper,
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { inkColorMap, rarityIcons } from './constants';
import LazyLoad from 'react-lazyload';
import Loading from '../components/Loading';

const InventoryGridList = ({ cards, handleCardClick, convertPrice, openListingModal, currentUser }) => {
  const [listings, setListings] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const fetchListings = async (card) => {
    try {
      const listingsSnapshot = await getDocs(
        collection(db, 'sets', card.setId, 'cards', card.docId, 'listings')
      );
      const listingsData = listingsSnapshot.docs.reduce((acc, doc) => {
        // Only include listings that belong to the current user
        if (doc.id === currentUser.uid) {
          const data = doc.data();
          acc[doc.id] = Object.entries(data).map(([condition, listing]) => ({
            condition,
            ...listing,
          }));
        }
        return acc;
      }, {});
      setListings((prevListings) => ({
        ...prevListings,
        [card.docId]: listingsData,
      }));
    } catch (error) {
      console.error('Error fetching listings:', error);
    }
  };

  useEffect(() => {
    cards.forEach((card) => {
      fetchListings(card);
    });
  }, [cards]);

  const handlePopoverToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const renderCard = useCallback(
    (card) => (
      <Grid item key={card.docId} xs={12} sm={6} md={4} lg={3}>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            backgroundColor: card.type === "Foil" ? "#fffbea" : "#ffffff",
            border:
              card.type === "Foil" ? "2px solid #ffd700" : "1px solid #e0e0e0",
            position: "relative",
            transform: "scale(0.9)",
          }}
        >
          <Box
            className={card.type === "Foil" ? "foil-image-container" : ""}
            sx={{ position: "relative", paddingTop: "130%" }}
          >
            <LazyLoad height={400} offset={1600}>
              <CardMedia
                className="foil-image"
                component="img"
                alt={card.name}
                image={card.image_uris?.digital?.normal || card.image_uris?.digital?.large}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "fallback_image_url";
                }}
              />
            </LazyLoad>
          </Box>

          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Avatar
                variant="square"
                sx={{
                  bgcolor: "#0C0A5A",
                  color: "white",
                  height: 32,
                  width: 32,
                }}
              >
                {card.collector_number}
              </Avatar>
              <Avatar
                variant="square"
                sx={{ bgcolor: "#0C0A5A", height: 32, width: 32 }}
              >
                <img
                  src={rarityIcons[card.rarity.replace(/_/g, " ")]}
                  alt={card.rarity}
                  style={{ width: 20, height: 20 }}
                />
              </Avatar>
              {card.type === "Foil" && (
                <Avatar
                  variant="square"
                  sx={{
                    width: 64,
                    height: 32,
                    bgcolor: "#ffd700",
                    border: "2px solid #000000",
                    color: "#000000",
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: "10px" }}>
                    COLD FOIL
                  </Typography>
                </Avatar>
              )}
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Stack direction="column" spacing={1}>
                <Typography gutterBottom variant="h6" component="div">
                  {card.name}
                </Typography>
                {card.version && (
                  <Typography variant="subtitle1" color="textSecondary">
                    {card.version}
                  </Typography>
                )}
              </Stack>
            </Box>
          </CardContent>
          <CardActions
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {listings[card.docId] ? (
                Object.values(listings[card.docId]).flatMap((userListings) => {
                  const firstListing = userListings[0];
                  const remainingListings = userListings.slice(1);

                  return (
                    <React.Fragment key={card.docId}>
                      <Box
                        sx={{
                          bgcolor: "secondary.main",
                          color: "white",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                        aria-owns={open ? 'mouse-over-popper' : undefined}
                        aria-haspopup="true"
                        onClick={handlePopoverToggle}
                      >
                        <Typography variant="body2">
                          {firstListing.condition} - Qty: {firstListing.quantity}
                          {remainingListings.length > 0 && (
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{ marginLeft: "4px" }}
                            >
                              +{remainingListings.length} more
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                      <Popper
                        id="mouse-over-popper"
                        sx={{
                          pointerEvents: 'auto',
                          bgcolor: "secondary.main", // Match the background color of the first listing
                          color: "white", // Set text color to white
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        disableRestoreFocus
                      >
                        <Stack direction="column" spacing={1} sx={{ p: 1 }}>
                          {remainingListings.map((listing, index) => (
                            <Typography key={index} variant="body2">
                              {listing.condition} - Qty: {listing.quantity}
                            </Typography>
                          ))}
                        </Stack>
                      </Popper>
                    </React.Fragment>
                  );
                })
              ) : (
                <Loading />
              )}
              <Box
                sx={{
                  bgcolor: "green",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => window.open(`https://www.tcgplayer.com/product/${card.tcgplayer_id}`, "_blank")}
              >
                <Typography variant="body2">
                  TCGP Price: {convertPrice(card.price || 0)}
                </Typography>
              </Box>
            </Box>
          </CardActions>
          <CardActionArea
            onClick={() => openListingModal(card)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px",
              bgcolor: "primary.main",
              color: "white",
              "&:hover": {
                bgcolor: "primary.dark",
              },
            }}
          >
            <Typography variant="button" sx={{ fontSize: "0.875rem" }}>
              Add/Update Listing
            </Typography>
          </CardActionArea>
        </Card>
      </Grid>
    ),
    [listings, handleCardClick, convertPrice, openListingModal, anchorEl]
  );

  return <Grid container columnSpacing={3}>{cards.map(renderCard)}</Grid>;
};

export default InventoryGridList;
