import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button, Box, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import heroImage from '../assets/hero-image.jpg';
import '../App.css';

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh', // Ensure it covers the full height
        width: '100%',
        backgroundImage: `url(${heroImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        overflow: 'hidden',
        margin: 0,
        padding: 0,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          zIndex: 1,
        }}
      />
      <Container 
        sx={{ 
          position: 'relative', 
          zIndex: 2, 
          color: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px', // Adjust the padding based on screen size
        }}
      >
        <Box marginTop={4} textAlign="center">
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontSize: { xs: '2rem', md: '3rem' },
            }}
          >
            Lorcana Card Marketplace
          </Typography>
          <Typography variant="h5" gutterBottom>
            Buy, Sell and Manage your Lorcana Cards
          </Typography>
        </Box>
        <Box marginTop={4}>
          <Grid container spacing={isMobile ? 2 : 4}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: 'center' }}>
                <StorefrontIcon style={{ fontSize: isMobile ? 50 : 80, color: '#3f51b5' }} />
                <Typography variant="h6" gutterBottom>
                  Card Marketplace
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/marketplace">
                  Go to Marketplace
                </Button>
              </Paper>
            </Grid>
            
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: 'center' }}>
                <Inventory2OutlinedIcon style={{ fontSize: isMobile ? 50 : 80, color: '#3f51b5' }} />
                <Typography variant="h6" gutterBottom>
                  My Inventory
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/inventory-management">
                  Manage Inventory
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: 'center' }}>
                <MonetizationOnOutlinedIcon style={{ fontSize: isMobile ? 50 : 80, color: '#3f51b5' }} />
                <Typography variant="h6" gutterBottom>
                  My Sales
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/sales">
                  View Sold Items
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: 'center' }}>
                <ShoppingBagOutlinedIcon style={{ fontSize: isMobile ? 50 : 80, color: '#3f51b5' }} />
                <Typography variant="h6" gutterBottom>
                  My Purchases
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/purchases">
                  View Purchases
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: 'center' }}>
                <FactCheckOutlinedIcon style={{ fontSize: isMobile ? 50 : 80, color: '#3f51b5' }} />
                <Typography variant="h6" gutterBottom>
                  My Collection
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/mycollection">
                  View Collection
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: 'center' }}>
                <PersonOutlineOutlinedIcon style={{ fontSize: isMobile ? 50 : 80, color: '#3f51b5' }} />
                <Typography variant="h6" gutterBottom>
                  My Profile
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/profile">
                  View Profile
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: 'center' }}>
                <StorefrontIcon style={{ fontSize: isMobile ? 50 : 80, color: '#3f51b5' }} />
                <Typography variant="h6" gutterBottom>
                  Bundle Marketplace
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/bundle-marketplace" disabled>
                  Coming Soon
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={{ padding: isMobile ? 1 : 2, textAlign: 'center' }}>
                <DynamicFeedIcon style={{ fontSize: isMobile ? 50 : 80, color: '#3f51b5' }} />
                <Typography variant="h6" gutterBottom>
                  My Sale Bundles
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/bundle-management" disabled>
                  Coming Soon
                </Button>
              </Paper>
            </Grid>
            
            
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
