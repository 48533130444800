import React, { useState } from 'react';
import { ListSubheader, Grid, Box, Typography, Avatar, Button, List, ListItem, ListItemText, ListItemAvatar, Divider, Link, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchSortToolbar from './SearchSortToolbar'; 

// Group sales by date
const groupSalesByDate = (sales) => {
  return sales.reduce((groups, sale) => {
    const date = sale.timestamp ? new Date(sale.timestamp.seconds * 1000).toLocaleDateString() : 'Unknown Date';
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(sale);
    return groups;
  }, {});
};

// Sorting logic
const sortSales = (sales, sortOption) => {
  return sales.sort((a, b) => {
    if (sortOption === 'date') {
      return b.timestamp.seconds - a.timestamp.seconds; // Newest first
    } else if (sortOption === 'total') {
      return b.total - a.total; // Highest total first
    }
    return 0;
  });
};

const SalesList = ({ sales, usernames, onOpenProfile, onCancelSale, onSelectSale, onSetPaymentRequestDialogOpen, onSetPrepareShippingDialogOpen, onSetMarkAsShippedDialogOpen, onRefundRequest, onUpdateRefundStatus, onViewDispute }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('date'); // Default to sorting by date
  const [collapsedSections, setCollapsedSections] = useState({});
  const [allCollapsed, setAllCollapsed] = useState(false); // Tracks whether all sections are collapsed or expanded

  // Filtered and sorted sales based on search term and selected sort option
  const filteredSales = sales.filter((sale) => {
    const buyerName = usernames[sale.buyerId] || sale.buyerId;
    const searchInItems = sale.items?.some((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    return (
      buyerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      sale.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(sale.total).includes(searchTerm) || // Allow search by total
      searchInItems
    );
  });

  const sortedSales = sortSales(filteredSales, sortOption);
  const groupedSales = groupSalesByDate(sortedSales);

  // Toggle collapse/expand for a specific date
  const toggleCollapse = (date) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  // Expand all or collapse all sections
  const toggleAllSections = (collapse) => {
    const newState = Object.keys(groupedSales).reduce((acc, date) => {
      acc[date] = collapse;
      return acc;
    }, {});
    setCollapsedSections(newState);
    setAllCollapsed(collapse);
  };

  return (
    <Box>

      <SearchSortToolbar
        searchTerm={searchTerm}
        sortOption={sortOption}
        onSearchChange={setSearchTerm}
        onSortChange={setSortOption}
        onToggleCollapse={toggleAllSections}
        allCollapsed={allCollapsed}
      />

      {/* List of Sales */}
      <List>
        {Object.keys(groupedSales).map((date) => (
          <React.Fragment key={date}>
            <ListSubheader sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6">{date}</Typography>
              <IconButton onClick={() => toggleCollapse(date)}>
                {collapsedSections[date] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </ListSubheader>

            {/* Check if section is collapsed */}
            {!collapsedSections[date] &&
              groupedSales[date].map((sale) => (
                <ListItem
                  key={sale.id}
                  sx={{
                    border: "2px solid #0C0A5A",
                    borderRadius: 2,
                    marginBottom: 2,
                    padding: 2,
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={4} md={5}>
                      <Box>
                        <Typography variant="h6">
                          Buyer:
                          <Link
                            onClick={() => onOpenProfile(sale.buyerId)}
                            sx={{ ml: 1 }}
                            style={{ cursor: "pointer" }}
                          >
                            <PersonIcon fontSize="small" />{" "}
                            {usernames[sale.buyerId] || sale.buyerId}
                          </Link>
                        </Typography>
                        <Typography variant="body1">
                          Total: ${sale.total.toFixed(2)} - Status: {sale.status}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          visibility: [
                            "Pending",
                            "Invoice Generated",
                            "Payment Requested",
                          ].includes(sale.status)
                            ? "visible"
                            : "hidden",
                          height: "auto",
                        }}
                      >
                        <Button
                          variant="text"
                          color="error"
                          onClick={() => onCancelSale(sale)}
                          fullWidth
                        >
                          Cancel Sale
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                      <List disablePadding>
                        {sale.items?.map((item) => (
                          <ListItem
                            key={item.docName}
                            sx={{ paddingLeft: 0, paddingBottom: 1 }}
                          >
                            <ListItemAvatar sx={{ marginRight: 2 }}>
                              <Avatar
                                variant="square"
                                src={item.cardImage || "fallback_image_url"}
                                sx={{
                                  height: "80px",
                                  width: "60px",
                                  objectFit: "contain",
                                  borderRadius: 1,
                                }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "fallback_image_url";
                                }}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <>
                                  <Typography variant="body1" component="div">
                                    {item.cardName}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {item.setName} - #{item.collector_number}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    {item.cardType} - {item.cardRarity}
                                  </Typography>
                                </>
                              }
                              secondary={
                                <>
                                  <Typography variant="body2" color="textSecondary">
                                    Quantity: {item.staticQuantity}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Price: $
                                    {typeof item.staticPrice === "number"
                                      ? item.staticPrice.toFixed(2)
                                      : item.staticPrice}
                                  </Typography>
                                </>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} sx={{ textAlign: "right" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        {sale.status === "Pending" && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              onSelectSale(sale);
                              onSetPaymentRequestDialogOpen(true);
                            }}
                            endIcon={<SendIcon />}
                          >
                            Request Payment
                          </Button>
                        )}
                        {sale.status === "Paid" && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              onSelectSale(sale);
                              onSetPrepareShippingDialogOpen(true);
                            }}
                          >
                            Prepare for Shipping
                          </Button>
                        )}
                        {sale.status === "Preparing for Shipping" && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              onSelectSale(sale);
                              onSetMarkAsShippedDialogOpen(true);
                            }}
                          >
                            Mark as Shipped
                          </Button>
                        )}
                        {sale.status === "Refund Requested" && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => onRefundRequest(sale)}
                          >
                            View Refund Request
                          </Button>
                        )}
                        {sale.status === "Refund Approved" && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onUpdateRefundStatus(sale)}
                          >
                            Update Refund Status
                          </Button>
                        )}
                        {sale.status === "Dispute Requested" && (
                          <Button
                            variant="contained"
                            color="warning"
                            onClick={() => onViewDispute(sale)}
                          >
                            View Dispute
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default SalesList;
