import React, { useState } from 'react';
import { Container, Typography, Box, Button, Grid, Paper } from '@mui/material';
import heroImage from '../assets/hero-image.jpg';
import LoginDialog from './LoginDialog';
import { useNavigate } from 'react-router-dom';
import '../App.css';

const Login = () => {
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const navigate = useNavigate();

  const openLoginDialog = () => {
    setLoginDialogOpen(true);
  };

  const closeLoginDialog = () => {
    setLoginDialogOpen(false);
  };

  const handleExploreMarketplace = () => {
    navigate('/public-marketplace');
  };

  return (
    <Box
      className="hero-container"
      sx={{
        backgroundImage: `url(${heroImage})`,
      }}
    >
      <Box className="hero-overlay" />
      <Container className="hero-content">
        <Box sx={{ mb: 4, textAlign: "center" }}>
          <Typography
            variant="h2"
            component="h1"
            sx={{ fontSize: { xs: "2rem", md: "3rem" } }}
          >
            Welcome to Lorcana Card Marketplace
          </Typography>
          <Typography variant="h5" gutterBottom>
            Buy, Sell, and Manage your Lorcana Cards with Ease
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Join a community of players and collectors to buy and sell Lorcana
            cards. Experience a seamless and user-friendly marketplace designed
            just for you.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: 4,
              gap: 2, // Adds space between the buttons and text
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={openLoginDialog}
              className="custom-button"
              sx={{ px: 3, py: 1.5, fontSize: "1rem" }}
            >
              Sign Up Now
            </Button>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "white" }}
            >
              or
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExploreMarketplace}
              className="custom-button"
              sx={{ px: 3, py: 1.5, fontSize: "1rem" }}
            >
              Explore Marketplace
            </Button>
          </Box>
        </Box>

        <Box marginTop={4} textAlign="center">
          <Typography variant="h4" gutterBottom>
            Why Choose Lorcana Card Marketplace?
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Paper className="grid-item-paper">
                <Typography variant="h6" gutterBottom>
                  Direct Transactions
                </Typography>
                <Typography>
                  Deal directly with sellers, eliminating the middleman for more
                  transparent and efficient transactions.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className="grid-item-paper">
                <Typography variant="h6" gutterBottom>
                  Easy Management
                </Typography>
                <Typography>
                  Stop hunting around at endless shops for singles, manage your
                  collection, sales, and purchases all in one place.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className="grid-item-paper">
                <Typography variant="h6" gutterBottom>
                  Verified Sellers & Ratings
                </Typography>
                <Typography>
                  Sellers must verify their identity before selling cards, you
                  can also check a sellers ratings before making a purchase.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <LoginDialog open={loginDialogOpen} onClose={closeLoginDialog} />
    </Box>
  );
};

export default Login;
