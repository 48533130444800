import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Box,
  CircularProgress,
  IconButton
} from '@mui/material';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DeleteIcon from '@mui/icons-material/Delete';
import { storage, db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import CameraDialog from './CameraDialog';

const PaymentRequestDialog = ({
  open,
  onClose,
  sale,
  message,
  onMessageChange,
  selectedPaymentOptions,
  onPaymentOptionChange,
  postageOptions,
  selectedPostageOptions,
  onPostageOptionChange,
  onRequestPayment,
  saleId,
  selectedSale,
  currentUser,
  capturedImages,
  setCapturedImages, 
  imageFiles,   
  setImageFiles 
}) => {
  const [customPaymentMethod, setCustomPaymentMethod] = useState('');
  const [customPaymentDetails, setCustomPaymentDetails] = useState('');
  const [paymentFee, setPaymentFee] = useState(0); 
  const [isValid, setIsValid] = useState(false);  
  const [cameraDialogOpen, setCameraDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false); 

  const paymentOptions = sale?.paymentOptions || {};

  useEffect(() => {
    if (!sale) return;

    const selectedOption = selectedPaymentOptions[0];
    if (selectedOption) {
      const optionDetails = paymentOptions[selectedOption];
      if (optionDetails) {
        const feeType = optionDetails.feeType || '%';
        const feeValue = parseFloat(optionDetails.fee || 0);

        let calculatedFee = 0;
        const subtotal = sale.items.reduce(
          (acc, item) => acc + (item.staticPrice * item.staticQuantity), 0
        );

        if (feeType === '%') {
          calculatedFee = (feeValue / 100) * subtotal;
        } else {
          calculatedFee = feeValue;
        }

        setPaymentFee(calculatedFee);
      }
    } else {
      setPaymentFee(0);
    }
  }, [selectedPaymentOptions, sale?.items, paymentOptions]);

  // Handle image upload input change
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(files);
  };

  // Open CameraDialog
  const handleOpenCameraDialog = () => {
    setCameraDialogOpen(true);
  };

  // Close CameraDialog
  const handleCloseCameraDialog = () => {
    setCameraDialogOpen(false);
  };

  // Handle capture from CameraDialog
  const handleCaptureFromCamera = (image) => {
    setCapturedImages((prevImages) => [...prevImages, image]); // Add captured image
  };

  // Remove a captured image
  const handleRemoveImage = (index) => {
    setCapturedImages(capturedImages.filter((_, i) => i !== index));
  };

  // Upload selected images and captured images to Firebase Storage and store URLs in Firestore
  const handleUploadImages = async () => {
    if (!saleId || !currentUser?.uid) {
      console.error('Sale ID or User ID is missing, cannot upload images.');
      return;
    }
  
    const imageUrls = [];
    setLoading(true); 
  
    // Upload image files from input
    for (const file of imageFiles) {
      console.log('Uploading file:', file.name);
      const storageRef = ref(storage, `sales/${saleId}/${file.name}`);
      const metadata = {
        customMetadata: {
          buyerId: selectedSale.buyerId,
          sellerId: currentUser.uid,
        },
      };
      await uploadBytes(storageRef, file, metadata);
      const url = await getDownloadURL(storageRef);
      console.log('Uploaded file URL:', url);
      imageUrls.push(url);
    }
  
    // Upload captured webcam images
    for (const capturedImage of capturedImages) {
      console.log('Uploading captured image...');
      const response = await fetch(capturedImage); // Convert data URL to blob
      const blob = await response.blob(); // Convert to a Blob
      const fileName = `captured-${Date.now()}.png`; // Create a unique filename
      const storageRef = ref(storage, `sales/${saleId}/${fileName}`);
      const metadata = {
        customMetadata: {
          buyerId: selectedSale.buyerId,
          sellerId: currentUser.uid,
        },
      };
      await uploadBytes(storageRef, blob, metadata);
      const url = await getDownloadURL(storageRef);
      console.log('Uploaded captured image URL:', url);
      imageUrls.push(url);
    }
  
    const saleRef = doc(db, `users/${currentUser.uid}/sales/${saleId}`);
    const purchaseRef = doc(db, `users/${selectedSale.buyerId}/purchases/${saleId}`);
  
    // Update Firestore with the image URLs
    await updateDoc(saleRef, { imageUrls }, { merge: true });
    await updateDoc(purchaseRef, { imageUrls }, { merge: true });
  
    setImageFiles([]); // Clear image files after upload
    setCapturedImages([]); // Clear captured images after upload
    setLoading(false); 
  };

  // Close CameraDialog and reset capturedImages
  const handleCameraDialogClose = () => {
    setCapturedImages([]); // Clear captured images when camera dialog is closed
    setCameraDialogOpen(false); // Close the dialog
  };

  const handleRequestPayment = async () => {
    setLoading(true); 
    if (imageFiles.length > 0 || capturedImages.length > 0) {
      await handleUploadImages();
    }
    onRequestPayment(customPaymentMethod, customPaymentDetails);
    setLoading(false);
  };

  // Validate form to check if all required fields are filled
  useEffect(() => {
    const hasImageFiles = imageFiles.length > 0 || capturedImages.length > 0;
    const hasSelectedPaymentOption = selectedPaymentOptions.length > 0;
    const hasSelectedPostageOption = selectedPostageOptions.length > 0;

    if (hasImageFiles && hasSelectedPaymentOption && hasSelectedPostageOption) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [imageFiles, capturedImages, selectedPaymentOptions, selectedPostageOptions]);

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Request Payment</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Items</Typography>
          <List>
            {sale.items?.map((item) => (
              <ListItem key={item.docName} disableGutters>
              <ListItemText
                primary={
                  <>
                    <Typography variant="body1" component="div">
                      {item.cardName}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.setName} - #{item.collector_number}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.cardType} - {item.cardRarity}
                    </Typography>
                  </>
                }
                secondary={
                  <>
                    <Typography variant="body2" color="textSecondary">
                      Quantity: {item.staticQuantity}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Price: $
                      {typeof item.staticPrice === "number"
                        ? item.staticPrice.toFixed(2)
                        : item.staticPrice}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            ))}
          </List>

          {/* Image Upload Section */}
          <Box mt={2}>
            <Typography variant="subtitle1">
              Add Images of Cards to show Card Condition
            </Typography>
            <input
              accept="image/*"
              multiple
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="upload-images"
            />
            <label htmlFor="upload-images">
              <Button variant="outlined" color="primary" component="span">
                Upload Photos
              </Button>
            </label>
            {imageFiles.length > 0 && (
              <Typography variant="body2" mt={1}>
                {imageFiles.length} image(s) selected
              </Typography>
            )}
          </Box>

          {/* Captured Images Section */}
          {capturedImages.length > 0 && (
            <Box mt={2}>
              <Typography variant="body2">Captured Photos:</Typography>
              <Box display="flex" flexWrap="wrap">
                {capturedImages.map((image, index) => (
                  <Box key={index} position="relative" m={1}>
                    <img
                      src={image}
                      alt={`Captured ${index}`}
                      style={{
                        width: "100px",
                        height: "auto",
                        border: "1px solid #ccc",
                      }}
                    />
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => handleRemoveImage(index)}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {/* Open Camera Dialog */}
          <Box mt={2}>
            <Button onClick={handleOpenCameraDialog} variant="outlined">
              Use Camera
            </Button>
          </Box>

          <FormControl component="fieldset" fullWidth margin="normal">
            <Typography variant="subtitle1">Payment Options</Typography>
            {paymentOptions.paypal && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedPaymentOptions.includes("paypal")}
                    onChange={() => onPaymentOptionChange("paypal")}
                  />
                }
                label="Paypal"
              />
            )}
            {paymentOptions.payID && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedPaymentOptions.includes("payID")}
                    onChange={() => onPaymentOptionChange("payID")}
                  />
                }
                label="PayID"
              />
            )}
            {paymentOptions.bankDeposit && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedPaymentOptions.includes("bankDeposit")}
                    onChange={() => onPaymentOptionChange("bankDeposit")}
                  />
                }
                label="Bank Transfer"
              />
            )}
          </FormControl>

          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Payment Fee: ${paymentFee.toFixed(2)}
          </Typography>

          <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
            Select the available shipping options below:
          </Typography>
          {postageOptions?.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedPostageOptions.includes(option.method)}
                  onChange={() => onPostageOptionChange(option.method)}
                />
              }
              label={`${option.method} - ${option.chargePerItem}`}
            />
          ))}

          <TextField
            label="Message to Buyer"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => onMessageChange(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleRequestPayment}
            color="primary"
            disabled={!isValid || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Send Request'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Camera Dialog */}
      <CameraDialog
        open={cameraDialogOpen}
        onClose={handleCloseCameraDialog}
        onCapture={handleCaptureFromCamera}
        capturedImages={capturedImages} // Pass down the captured images
        onRemoveImage={handleRemoveImage} // Pass down the remove handler
      />
    </>
  );
};

export default PaymentRequestDialog;
