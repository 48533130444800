import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, collection, addDoc, deleteDoc } from 'firebase/firestore';
import {
  List, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, Box, Button, Typography, Divider, Container, Dialog, DialogTitle, DialogContent, IconButton, Link, isMobile, useMediaQuery, useTheme
} from '@mui/material';
import { useAuth } from '../auth';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CardDetail from './CardDetail'; // Import the CardDetail component
import BundleMarketplaceDetails from './BundleMarketplaceDetails'; // Import the BundleDetails component
import { useAppBarContext } from '../components/AppBarContext'; // Import the AppBarContext
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PersonIcon from '@mui/icons-material/Person';
import PublicProfile from '../pages/PublicProfile'; // Import PublicProfile component
import Loading from '../components/Loading';
import withAnalytics from '../hoc/withAnalytics';

const currencySymbols = {
  AUD: '$',
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  // Add more currencies as needed
};

const Cart = () => {
  const { currentUser } = useAuth();
  const { selectedCountry, exchangeRates } = useAppBarContext(); // Use the AppBarContext
  const [cartItems, setCartItems] = useState([]);
  const [usernames, setUsernames] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemDetails, setItemDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchCartItems = async () => {
      if (currentUser) {
        const cartRef = doc(db, 'carts', currentUser.uid);
        const cartDoc = await getDoc(cartRef);
        if (cartDoc.exists()) {
          const items = cartDoc.data().items;
          const detailedItems = await Promise.all(items.map(async (item) => {
            if (!item.setId || !item.docName) {
              console.error('Missing setId or docName:', item);
              return item;
            }
            const cardDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName));
            return cardDoc.exists() ? { ...item, cardData: cardDoc.data() } : item;
          }));
          setCartItems(detailedItems);

          // Fetch usernames
          const sellerIds = Array.from(new Set(detailedItems.map(item => item.type === 'bundle' ? item.userId : item.sellerId)));
          const usernamePromises = sellerIds.map(async (id) => {
            const userDoc = await getDoc(doc(db, 'users', id));
            return { id, username: userDoc.exists() ? userDoc.data().username : id };
          });
          const usernameResults = await Promise.all(usernamePromises);
          const usernameMap = usernameResults.reduce((acc, { id, username }) => {
            acc[id] = username;
            return acc;
          }, {});
          setUsernames(usernameMap);
        }
        setLoading(false);
      }
    };

    fetchCartItems();
  }, [currentUser]);

  const handleItemClick = async (item) => {
    try {
      setSelectedItem(item);
      setDialogOpen(true);

      if (item.type === 'listing') {
        if (!item.setId || !item.docName) {
          console.error('Missing setId or docName:', item);
          return;
        }
        const cardDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName));
        const listingDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName, 'listings', item.listingId));
        if (cardDoc.exists() && listingDoc.exists()) {
          setItemDetails({ cardData: { ...cardDoc.data(), id: cardDoc.id, setId: item.setId }, listingData: listingDoc.data(), type: 'listing' });
        } else {
          setItemDetails(null);
        }
      } else if (item.type === 'bundle') {
        const bundleRef = doc(db, 'bundlelistings', item.userId, 'bundles', item.id);
        const bundleDoc = await getDoc(bundleRef);
        if (bundleDoc.exists()) {
          setItemDetails({ bundleData: bundleDoc.data(), type: 'bundle' });
        } else {
          setItemDetails(null);
        }
      }
    } catch (error) {
      setItemDetails(null);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setItemDetails(null);
  };

  const handleProfileDialogClose = () => {
    setProfileDialogOpen(false);
    setSelectedUserId(null);
  };

  const handleOpenProfileDialog = (userId) => {
    setSelectedUserId(userId);
    setProfileDialogOpen(true);
  };

  const handleCheckout = async (sellerId) => {
    const sellerItems = cartItems.filter(item => (item.type === 'bundle' ? item.userId : item.sellerId) === sellerId);
  
    const salesRef = collection(db, 'users', sellerId, 'sales');
    
    const saleItems = await Promise.all(sellerItems.map(async item => {
      const cardDoc = await getDoc(doc(db, 'sets', item.setId, 'cards', item.docName));
      const convertedPrice = convertPrice(item.price);
      const staticPrice = `${convertedPrice}`;
  
      return {
        docName: item.docName,
        setId: item.setId,
        setName: item.cardData?.set?.name || '',
        listingId: item.listingId,
        quantity: item.quantity,
        price: staticPrice,
        staticPrice: staticPrice,
        staticQuantity: item.quantity,
        cardName: item.cardData?.name || '',
        cardImage: item.cardData?.image_uris?.digital?.small || '',
        cardType: item.cardData?.type || '',
        cardRarity: item.cardData?.rarity || '',
        cardInk: item.cardData?.ink || '',
        collector_number: cardDoc.exists() ? cardDoc.data().collector_number : '',
      };
    }));
  
    const saleDetails = {
      buyerId: currentUser.uid,
      items: saleItems,
      total: saleItems.reduce((sum, item) => sum + (item.price * item.quantity), 0),
      status: 'Pending',
      timestamp: new Date(),
    };
  
    try {
      const saleDoc = await addDoc(salesRef, saleDetails);
  
      const purchaseDetails = {
        buyerId: currentUser.uid,
        items: saleItems.map(item => ({
          ...item,
          setName: item.setName,
          staticQuantity: item.staticQuantity,
          staticPrice: item.staticPrice,
        })),
        sellerId: sellerId,
        total: saleDetails.total,
        status: 'Pending',
        timestamp: saleDetails.timestamp,
      };
  
      const purchasesRef = doc(db, 'users', currentUser.uid, 'purchases', saleDoc.id);
      await setDoc(purchasesRef, purchaseDetails);
  
      for (const item of sellerItems) {
        const listingRef = doc(db, 'sets', item.setId, 'cards', item.docName, 'listings', item.listingId);
        const listingDoc = await getDoc(listingRef);
  
        if (listingDoc.exists()) {
          const listingData = listingDoc.data();
          const listingKey = Object.keys(listingData)[0];
          const listingInfo = listingData[listingKey];
  
          const updatedQuantity = listingInfo.quantity - item.quantity;
          if (updatedQuantity <= 0) {
            await deleteDoc(listingRef);
          } else {
            await setDoc(listingRef, {
              [listingKey]: {
                ...listingInfo,
                quantity: updatedQuantity,
              },
            }, { merge: true });
          }
        }
      }
  
      const remainingItems = cartItems.filter(item => (item.type === 'bundle' ? item.userId : item.sellerId) !== sellerId);
      await setDoc(doc(db, 'carts', currentUser.uid), { items: remainingItems });
      setCartItems(remainingItems);
  
      alert(`Checked out items from seller ${usernames[sellerId] || sellerId}. Go to My Purchases to check the status of your order.`);
    } catch (error) {
      alert('There was an error during checkout. Please try again.');
    }
  };

  const handleRemoveItem = async (itemToRemove) => {
    const remainingItems = cartItems.filter(item => 
      item.docName !== itemToRemove.docName || 
      item.setId !== itemToRemove.setId || 
      item.listingId !== itemToRemove.listingId || 
      item.condition !== itemToRemove.condition
    );
    await setDoc(doc(db, 'carts', currentUser.uid), { items: remainingItems });
    setCartItems(remainingItems);
  };

  const handleQuantityChange = async (item, delta) => {
    try {
      console.log("Handling quantity change...");
  
      // Check and log necessary properties before proceeding
      if (!item.setId || !item.docName || !item.listingId) {
        console.error("Missing setId, docName, or listingId:", item);
        return;
      }
  
      console.log("Item details before quantity change:", item);
  
      // Fetch the listing document
      const listingRef = doc(db, 'sets', item.setId, 'cards', item.docName, 'listings', item.listingId);
      const listingDoc = await getDoc(listingRef);
  
      if (!listingDoc.exists()) {
        console.error("Error: Listing document does not exist.", { item, listingRef });
        return;
      }
  
      const listingData = listingDoc.data();
      console.log("Complete Listing Data:", listingData);
  
      // Get the first key in the listing data
      const listingKey = Object.keys(listingData)[0];
      console.log("Listing Key Used:", listingKey);
  
      // Ensure we have a valid listing key
      if (!listingKey || !listingData[listingKey]) {
        console.error("Error: Listing data not found. ListingKey:", listingKey, "ListingData:", listingData);
        return;
      }
  
      // Access the specific data to get the quantity
      const listingInfo = listingData[listingKey];
      const maxQuantity = listingInfo.quantity;
      console.log("Max Quantity from DB:", maxQuantity);
  
      // Validate the quantity
      if (typeof maxQuantity !== 'number') {
        console.error("Error: Quantity is missing or not a number in the listing data. ListingInfo:", listingInfo);
        return;
      }
  
      // Determine the correct price, whether it's the TCGP price or the manually set price
      const price = listingInfo.autoUpdatePrice 
        ? (item.cardData.price * (listingInfo.percentage / 100)).toFixed(2) 
        : listingInfo.price;
  
      // Update the cart items with the new quantity and correct price, ensuring it does not exceed the maxQuantity
      const updatedCartItems = cartItems.map(cartItem => {
        if (cartItem.docName === item.docName && cartItem.setId === item.setId && cartItem.listingId === item.listingId) {
          const newQuantity = Math.max(1, Math.min(cartItem.quantity + delta, maxQuantity));
          console.log("New Quantity after Change:", newQuantity);
          return { ...cartItem, quantity: newQuantity, price: price }; // Update with the correct price
        }
        return cartItem;
      });
  
      // Save the updated cart items back to the database
      await setDoc(doc(db, 'carts', currentUser.uid), { items: updatedCartItems });
      setCartItems(updatedCartItems);
    } catch (error) {
      console.error("Error in handleQuantityChange:", error);
    }
  };
  
  
  
  
  

  const convertPrice = (price) => {
    if (!exchangeRates[selectedCountry]) return price;
    return (price * exchangeRates[selectedCountry]).toFixed(2);
  };

  const groupCartItems = (items) => {
    const groupedItems = {};

    items.forEach((item) => {
      const key = `${item.docName}_${item.setId}_${item.listingId}_${item.condition}`;
      if (groupedItems[key]) {
        groupedItems[key].quantity += item.quantity;
      } else {
        groupedItems[key] = { ...item, quantity: item.quantity };
      }
    });

    return Object.values(groupedItems);
  };

  if (loading) {
    return <Loading />;
  }

  const groupedItems = groupCartItems(cartItems).reduce((acc, item) => {
    const sellerKey = item.type === 'bundle' ? item.userId : item.sellerId;
    if (!acc[sellerKey]) {
      acc[sellerKey] = [];
    }
    acc[sellerKey].push(item);
    return acc;
  }, {});

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        My Cart
      </Typography>
  
      {cartItems.length === 0 ? (
        <Typography variant="h6" color="textSecondary">
          Your cart is empty.
        </Typography>
      ) : (
        Object.keys(groupedItems).map((sellerKey) => {
          const sellerItems = groupedItems[sellerKey];
          const total = sellerItems.reduce(
            (sum, item) => sum + item.price * item.quantity,
            0
          );

        return (
          <Box key={sellerKey} mb={4}>
            <Typography variant="h6" gutterBottom>
              Seller:
              <Link
                onClick={() => handleOpenProfileDialog(sellerKey)}
                sx={{ ml: 1 }}
                style={{ cursor: "pointer" }}
              >
                <PersonIcon fontSize="small" />{" "}
                {usernames[sellerKey] || sellerKey}
              </Link>
            </Typography>
            <List>
              {sellerItems.map((item) => (
                <ListItem
                button
                onClick={() => handleItemClick(item)}
                key={`${item.docName}_${item.setId}_${item.listingId}_${item.condition}`}
                sx={{
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'flex-start' : 'center',
                }}
              >
                  <ListItemAvatar sx={{ width: 100 }}>
                    <Avatar
                      variant="square"
                      src={
                        item.cardData?.image_uris?.digital?.small ||
                        "fallback_image_url"
                      }
                      sx={{
                        height: "20%",
                        width: "80%",
                        objectFit: "contain",
                        border: `4px solid #000000`,
                      }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "fallback_image_url";
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      item.type === "bundle" ? item.name : item.cardData?.name
                    }
                    secondary={
                      item.type === "bundle" ? (
                        ""
                      ) : (
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            display="block"
                          >
                            Card Number: {item.collector_number}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            display="block"
                          >
                            Card Type: {item.cardData?.type}
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            display="block"
                          >
                            Condition: {item.condition}
                          </Typography>
                        </React.Fragment>
                      )
                    }
                  />

                  <ListItemSecondaryAction>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={() => handleQuantityChange(item, -1)}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography sx={{ mx: 1 }}>{item.quantity}</Typography>
                      <IconButton onClick={() => handleQuantityChange(item, 1)}>
                        <AddIcon />
                      </IconButton>
                    </Box>
                    <Typography>{`Price Each: ${
                      currencySymbols[selectedCountry]
                    }${convertPrice(item.price)}`}</Typography>
                    <IconButton
                      edge="end"
                      color="inherit"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            <Typography variant="h6" gutterBottom>
              Total: {currencySymbols[selectedCountry]}
              {convertPrice(total)}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleCheckout(sellerKey)}
            >
              Checkout Items from {usernames[sellerKey] || sellerKey}
            </Button>
            <Divider sx={{ my: 2 }} />
            </Box>
        );
      })
    )}

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleDialogClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {itemDetails ? (
            itemDetails.type === 'listing' ? (
              <Box>
                <CardDetail setId={itemDetails.cardData.setId} id={itemDetails.cardData.id} />
                <Typography variant="h6">{`Seller: ${usernames[itemDetails.listingData.sellerId] || itemDetails.listingData.sellerId}`}</Typography>
                <Typography variant="h6">{`Price: ${currencySymbols[selectedCountry]}${convertPrice(itemDetails.listingData.price)}`}</Typography>
              </Box>
            ) : (
              <Box>
                <BundleMarketplaceDetails bundle={itemDetails.bundleData} onClose={handleDialogClose} />
                <Typography variant="h6">{`Seller: ${usernames[itemDetails.bundleData.userId] || itemDetails.bundleData.userId}`}</Typography>
              </Box>
            )
          ) : (
            <Loading />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={profileDialogOpen} onClose={handleProfileDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton edge="end" color="inherit" onClick={handleProfileDialogClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedUserId && <PublicProfile userId={selectedUserId} />}
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default withAnalytics(Cart);