import React from 'react';
import { Grid, Card, CardMedia, CardContent, CardActions, Button, Box, Typography, Avatar, Chip, Stack } from '@mui/material';
import { inkColorMap, rarityIcons } from './constants';
import LazyLoad from 'react-lazyload';

const CardGrid = ({ cards, handleCardClick, convertPrice }) => {
  return (
    <Grid container spacing={3}>
      {cards.map((card) => (
        <Grid item key={card.docName} xs={12} sm={6} md={4} lg={3}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              backgroundColor: card.type === "Foil" ? "#fffbea" : "#ffffff",
              border:
                card.type === "Foil"
                  ? "2px solid #ffd700"
                  : "1px solid #e0e0e0",
              position: "relative",
            }}
          >
            <Box
              className={card.type === "Foil" ? "foil-image-container" : ""}
              sx={{ position: "relative", paddingTop: "130%" }}
            >
              <LazyLoad height={400} offset={1600}>
                <CardMedia
                  className="foil-image"
                  component="img"
                  alt={card.name}
                  image={card.image_uris?.digital?.normal || card.image_uris?.digital?.large}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "fallback_image_url";
                  }}
                ></CardMedia>
              </LazyLoad>
            </Box>
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Avatar
                  variant="square"
                  sx={{ bgcolor: "#0C0A5A", color: "white", height: 40 }}
                >
                  {card.collector_number}
                </Avatar>
                <Avatar variant="square" sx={{ bgcolor: "#0C0A5A", height: 40 }}>
                  <img
                    src={rarityIcons[card.rarity]}
                    alt={card.rarity}
                    style={{ width: 24, height: 24 }}
                  />
                </Avatar>
                {card.type === "Foil" && (
                <Avatar
                  variant="square"
                  sx={{
                    width: 70,
                    height: 36,
                    bgcolor: "#ffd700",
                    border: "2px solid #000000",
                    color: "#000000",
                  }}
                >
                  <Typography variant="h6" sx={{ fontSize: "10px" }}>COLD FOIL</Typography>
                </Avatar>
              )}
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Stack direction="column" spacing={1}>
              <Typography gutterBottom variant="h6" component="div">
                {card.name}
              </Typography>
              {card.version && (
                <Typography variant="subtitle1" color="textSecondary">
                  {card.version}
                </Typography>
              )}
            </Stack>
                </Box>
            </CardContent>
            <CardActions
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Button
                size="small"
                onClick={() =>
                  handleCardClick({ setId: card.setId, docName: card.docName })
                }
              >
                View Details
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Chip
                    sx={{
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                      },
                      borderRadius: "4px", 
                      bgcolor: "secondary.main",
                      color: "white",
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span>Listings</span>
                        {card.listingsCount || 0}
                      </Box>
                    }
                  />
                </Box>
                <Chip
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                    borderRadius: "4px", 
                    bgcolor: "green",
                    color: "white",
                  }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span>TCGP Price</span>
                      {convertPrice(card.price || 0)}
                    </Box>
                  }
                />
              </Box>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardGrid;
