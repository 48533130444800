import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import {
  Container, Accordion, AccordionSummary, AccordionDetails, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Table, TableBody, TableCell, TableRow, MenuItem, Select, InputLabel, FormControl, Grid, Paper
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserListings from './UserListings';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [expandedUserId, setExpandedUserId] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openListingsDialog, setOpenListingsDialog] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalActiveSubscriptions, setTotalActiveSubscriptions] = useState(0);

  useEffect(() => {
    fetchUsers();
    fetchSubscriptionPlans();
  }, []);

  // Fetch users from Firestore and calculate the counters
  const fetchUsers = async () => {
    const usersSnapshot = await getDocs(collection(db, 'users'));
    const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(usersData);
    
    // Set total users
    setTotalUsers(usersData.length);

    // Count users with active subscriptions
    const activeSubscriptionsCount = usersData.filter(user => user.subscription?.status === 'active').length;
    setTotalActiveSubscriptions(activeSubscriptionsCount);
  };

  // Fetch subscription plans from Firestore
  const fetchSubscriptionPlans = async () => {
    try {
      const docRef = doc(db, 'subscriptions', 'products');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const plans = docSnap.data().plans || [];
        setSubscriptionPlans(plans);
      }
    } catch (error) {
      console.error('Error fetching subscription plans:', error);
    }
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditedData(user);
    setOpenEditDialog(true);
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setOpenDeleteDialog(true);
  };

  const handleEditChange = (field, value) => {
    const fieldParts = field.split('.');
    setEditedData(prevData => {
      let newData = { ...prevData };
      let current = newData;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        if (!current[fieldParts[i]]) {
          current[fieldParts[i]] = {};
        }
        current = current[fieldParts[i]];
      }
      current[fieldParts[fieldParts.length - 1]] = value;
      return newData;
    });
  };

  const saveUserChanges = async () => {
    const userRef = doc(db, 'users', selectedUser.id);
    await updateDoc(userRef, editedData);
    setOpenEditDialog(false);
    fetchUsers();
  };

  const deleteUser = async () => {
    const userRef = doc(db, 'users', selectedUser.id);
    await deleteDoc(userRef);
    setOpenDeleteDialog(false);
    fetchUsers();
  };

  const handleAccordionChange = (userId) => (event, isExpanded) => {
    setExpandedUserId(isExpanded ? userId : null);
  };

  const handleViewListings = (user) => {
    setSelectedUser(user);
    setOpenListingsDialog(true);
  };

  return (
    <Container>
      {/* Dashboard Counters */}
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">Total Users</Typography>
            <Typography variant="h4" sx={{ color: 'primary.main' }}>
              {totalUsers}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6">Total Active Subscriptions</Typography>
            <Typography variant="h4" sx={{ color: 'primary.main' }}>
              {totalActiveSubscriptions}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>
      {users.map(user => (
        <Accordion key={user.id} expanded={expandedUserId === user.id} onChange={handleAccordionChange(user.id)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{user.firstName} {user.lastName}</Typography>
            <Typography sx={{ color: 'text.secondary', marginLeft: 'auto' }}>{user.email}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>{user.firstName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Name</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{user.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>{user.username}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>{user.phoneNumber}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Verification Status</TableCell>
                  <TableCell>{user.verificationStatus}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>{user.country}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Timezone</TableCell>
                  <TableCell>{user.timezone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Display Name</TableCell>
                  <TableCell>{user.displayName}</TableCell>
                </TableRow>

                {/* Seller Preferences */}
                <TableRow>
                  <TableCell>Seller Payment Options</TableCell>
                  <TableCell>
                    PayPal: {user.sellerPreferences?.paymentOptions?.paypal?.paypalID ||'N/A'} <br />
                    PayID: {user.sellerPreferences?.paymentOptions?.payID?.payID || 'N/A'} <br />
                    Bank Deposit: {user.sellerPreferences?.paymentOptions?.bankDeposit?.accountName || 'N/A'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Seller Postage Options</TableCell>
                  <TableCell>
                    {user.sellerPreferences?.postageOptions?.map((option, index) => (
                      <div key={index}>{option.method} - {option.chargePerItem}</div>
                    )) || 'N/A'}
                  </TableCell>
                </TableRow>

                {/* Buyer Preferences */}
                <TableRow>
                  <TableCell>Buyer Preferences</TableCell>
                  <TableCell>
                    Contact Name: {user.buyerPreferences?.preferredContactName || 'N/A'} <br />
                    Address: {user.buyerPreferences?.addressLine1 || 'N/A'}, {user.buyerPreferences?.city || 'N/A'}, {user.buyerPreferences?.state || 'N/A'}, {user.buyerPreferences?.zipCode || 'N/A'} <br />
                    Country: {user.buyerPreferences?.country || 'N/A'}
                  </TableCell>
                </TableRow>

                {/* Subscription */}
                <TableRow>
                  <TableCell>Subscription Plan</TableCell>
                  <TableCell>{user.subscription?.plan || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Subscription Status</TableCell>
                  <TableCell>{user.subscription?.status || 'N/A'}</TableCell>
                </TableRow>

                {/* Reviews */}
                <TableRow>
                  <TableCell>Reviews</TableCell>
                  <TableCell>
                    {user.reviews?.map((review, index) => (
                      <div key={index}>
                        Purchase ID: {review.purchaseId} - Rating: {review.rating} - Comment: {review.comment || 'No comment'}
                      </div>
                    )) || 'No Reviews'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Button variant="outlined" color="primary" sx={{ mt: 2 }} onClick={() => handleViewListings(user)}>
              View Listings
            </Button>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
              <Button onClick={() => handleEditUser(user)} variant="contained" color="primary" sx={{ marginRight: 2 }}>
                Edit
              </Button>
              <Button onClick={() => handleDeleteUser(user)} variant="contained" color="secondary">
                Delete
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Edit User Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          {/* User Information */}
          <TextField
            label="First Name"
            fullWidth
            margin="normal"
            value={editedData.firstName || ''}
            onChange={(e) => handleEditChange('firstName', e.target.value)}
          />
          <TextField
            label="Last Name"
            fullWidth
            margin="normal"
            value={editedData.lastName || ''}
            onChange={(e) => handleEditChange('lastName', e.target.value)}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={editedData.email || ''}
            onChange={(e) => handleEditChange('email', e.target.value)}
          />
          <TextField
            label="Username"
            fullWidth
            margin="normal"
            value={editedData.username || ''}
            onChange={(e) => handleEditChange('username', e.target.value)}
          />
          <TextField
            label="Phone Number"
            fullWidth
            margin="normal"
            value={editedData.phoneNumber || ''}
            onChange={(e) => handleEditChange('phoneNumber', e.target.value)}
          />

          {/* Verification Status */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Verification Status</InputLabel>
            <Select
              value={editedData.verificationStatus || ''}
              onChange={(e) => handleEditChange('verificationStatus', e.target.value)}
              label="Verification Status"
            >
              <MenuItem value="Verified">Verified</MenuItem>
              <MenuItem value="Not Verified">Not Verified</MenuItem>
            </Select>
          </FormControl>

          {/* Seller Preferences */}
          <Typography variant="h6" sx={{ mt: 2 }}>Seller Preferences</Typography>
          <TextField
            label="PayPal ID"
            fullWidth
            margin="normal"
            value={editedData.sellerPreferences?.paymentOptions?.paypal?.paypalID || ''}
            onChange={(e) => handleEditChange('sellerPreferences.paymentOptions.paypal.paypalID', e.target.value)}
          />
          <TextField
            label="PayID"
            fullWidth
            margin="normal"
            value={editedData.sellerPreferences?.paymentOptions?.payID?.payID || ''}
            onChange={(e) => handleEditChange('sellerPreferences.paymentOptions.payID.payID', e.target.value)}
          />
          <TextField
            label="Bank Deposit Name"
            fullWidth
            margin="normal"
            value={editedData.sellerPreferences?.paymentOptions?.bankDeposit?.accountName || ''}
            onChange={(e) => handleEditChange('sellerPreferences.paymentOptions.bankDeposit.accountName', e.target.value)}
          />

          {/* Buyer Preferences */}
          <Typography variant="h6" sx={{ mt: 2 }}>Buyer Preferences</Typography>
          <TextField
            label="Contact Name"
            fullWidth
            margin="normal"
            value={editedData.buyerPreferences?.preferredContactName || ''}
            onChange={(e) => handleEditChange('buyerPreferences.preferredContactName', e.target.value)}
          />
          <TextField
            label="Address Line 1"
            fullWidth
            margin="normal"
            value={editedData.buyerPreferences?.addressLine1 || ''}
            onChange={(e) => handleEditChange('buyerPreferences.addressLine1', e.target.value)}
          />
          <TextField
            label="City"
            fullWidth
            margin="normal"
            value={editedData.buyerPreferences?.city || ''}
            onChange={(e) => handleEditChange('buyerPreferences.city', e.target.value)}
          />
          <TextField
            label="State"
            fullWidth
            margin="normal"
            value={editedData.buyerPreferences?.state || ''}
            onChange={(e) => handleEditChange('buyerPreferences.state', e.target.value)}
          />
          <TextField
            label="ZIP Code"
            fullWidth
            margin="normal"
            value={editedData.buyerPreferences?.zipCode || ''}
            onChange={(e) => handleEditChange('buyerPreferences.zipCode', e.target.value)}
          />

          {/* Subscription Plan */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Subscription Plan</InputLabel>
            <Select
              value={editedData.subscription?.plan || ''}
              onChange={(e) => handleEditChange('subscription.plan', e.target.value)}
              label="Subscription Plan"
            >
              {subscriptionPlans.map((plan, index) => (
                <MenuItem key={index} value={plan.name}>
                  {plan.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Subscription Status</InputLabel>
            <Select
              value={editedData.subscription?.status || ''}
              onChange={(e) => handleEditChange('subscription.status', e.target.value)}
              label="Subscription Status"
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
              <MenuItem value="incomplete">Incomplete</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={saveUserChanges} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete User Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {selectedUser?.firstName} {selectedUser?.lastName}? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={deleteUser} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* User Listings */}
      {selectedUser && (
        <UserListings
          userId={selectedUser.id}
          open={openListingsDialog}
          onClose={() => setOpenListingsDialog(false)}
        />
      )}
    </Container>
  );
};

export default UserManagement;
