import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { analytics, db } from './firebase';
import { getDoc, doc } from 'firebase/firestore';
import { logEvent } from "firebase/analytics";
import Home from './pages/Home';
import CardMarketplace from './marketplace/CardMarketplace';
import BundleMarketplace from './marketplace/BundleMarketplace';
import Login from './pages/Login';
import Register from './pages/Register';
import Confirm from './pages/Confirm'; // Import the Confirm component
import Profile from './pages/Profile';
import Registration from './pages/Registration';
import InventoryManagement from './marketplace/InventoryManagement';
import Cart from './marketplace/Cart';
import BundleManagement from './marketplace/BundleManagement';
import BundleListings from './marketplace/BundleListings';
import SellerSales from './pages/SellerSales';
import BuyerPurchases from './pages/BuyerPurchases';
import MyCollection from './collection/MyCollection';
import AppHeader from './components/AppBar';
import PublicAppBar from './components/PublicAppBar';
import Footer from './components/Footer';
import { AppBarProvider } from './components/AppBarContext';
import { AuthProvider, useAuth } from './auth';
import Contact from './pages/Contact';
import ReportIssue from './pages/ReportIssue';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PasswordReset from './components/PasswordReset';
import TermsOfService from './pages/TermsOfService';
import { Box } from '@mui/material';
import FirestoreViewer from './FirestoreViewer';
import PublicProfileHandler from './pages/PublicProfileHandler';
import VerificationRequired from './pages/VerificationRequired';
import PublicCardMarketplace from './marketplace/PublicCardMarketplace';
import UserManagement from './pages/UserManagement';
import ManageSubscriptionPlans from './pages/ManageSubscriptionPlans';
import PaymentSuccess from './pages/PaymentSuccess';
import Loading from './components/Loading';
import { SubscriptionProvider } from './context/SubscriptionContext';
import TermsCheck from './pages/TermsCheck';
import './App.css';

const PrivateRoute = ({ element: Component, requiresVerification = false, restrictedToUserId = null, ...rest }) => {
  const { currentUser, loading } = useAuth();
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [checkingTerms, setCheckingTerms] = useState(true);

  useEffect(() => {
    const checkTerms = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setAgreedToTerms(userData.agreedToTerms || false);
        }
      }
      setCheckingTerms(false);
    };
    checkTerms();
  }, [currentUser]);

  // Show loading spinner while checking terms
  if (loading || checkingTerms) {
    return <Loading />;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (!agreedToTerms) {
    return <Navigate to="/tos-agreement" />;
  }

  if (requiresVerification && currentUser.verificationStatus !== 'Verified') {
    return <VerificationRequired />;
  }

  if (restrictedToUserId && currentUser.uid !== restrictedToUserId) {
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};


function App() {
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'page_view', { 
        page_title: document.title, 
        page_path: window.location.pathname 
      });
    }
  }, []);
  return (
    <AuthProvider>
      <AppBarProvider>
        <SubscriptionProvider>
          <Router>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
              }}
            >
              <Header />

              {/* Main Content */}
              <Box
                sx={{
                  flex: "1 0 auto",
                  paddingTop: "64px",
                  width: "100%",
                }}
              >
                <Routes>
                  {/* Public Routes */}
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/confirm" element={<Confirm />} />
                  <Route path="/tos-agreement" element={<TermsCheck />} />

                  <Route
                    path="/public-marketplace"
                    element={<PublicCardMarketplace />}
                  />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/report-issue" element={<ReportIssue />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-of-service"
                    element={<TermsOfService />}
                  />
                  <Route path="/reset-password" element={<PasswordReset />} />
                  <Route
                    path="/public/:username"
                    element={<PublicProfileHandler />}
                  />
                  <Route
                    path="/public/:username/listings"
                    element={<PublicProfileHandler />}
                  />
                  <Route path="/success" element={<PaymentSuccess />} />

                  {/* Private Routes */}
                  <Route path="/" element={<PrivateRoute element={Home} />} />
                  <Route
                    path="/marketplace"
                    element={
                      <PrivateRoute
                        element={CardMarketplace}
                      />
                    }
                  />
                  <Route
                    path="/cart"
                    element={
                      <PrivateRoute element={Cart} />
                    }
                  />
                  <Route
                    path="/profile"
                    element={<PrivateRoute element={Profile} />}
                  />
                  <Route
                    path="/registration"
                    element={<PrivateRoute element={Registration} />}
                  />
                  <Route
                    path="/inventory-management"
                    element={
                      <PrivateRoute
                        element={InventoryManagement}
                        requiresVerification
                      />
                    }
                    
                  />
                  {/*
                  <Route
                    path="/bundle-management"
                    element={
                      <PrivateRoute
                        element={BundleManagement}
                        requiresVerification
                      />
                    }
                  />

                  <Route
                    path="/bundlelistings"
                    element={
                      <PrivateRoute
                        element={BundleListings}
                        requiresVerification
                      />
                    }
                  />
                  <Route
                    path="/bundle-marketplace"
                    element={
                      <PrivateRoute
                        element={BundleMarketplace}
                        requiresVerification
                      />
                    }
                  />
                  */}
                  <Route
                    path="/mycollection"
                    element={<PrivateRoute element={MyCollection} />}
                  />
                  <Route
                    path="/sales"
                    element={<PrivateRoute element={SellerSales} />}
                  />
                  <Route
                    path="/purchases"
                    element={<PrivateRoute element={BuyerPurchases} />}
                  />
                  <Route
                    path="/firestoreviewer"
                    element={
                      <PrivateRoute
                        element={FirestoreViewer}
                        restrictedToUserId="WiWfYEuXZAe6x9gk58snlCmsIWW2"
                      />
                    }
                  />
                  <Route
                    path="/usermanagement"
                    element={
                      <PrivateRoute
                        element={UserManagement}
                        restrictedToUserId="WiWfYEuXZAe6x9gk58snlCmsIWW2"
                      />
                    }
                  />
                  <Route
                    path="/planmanagement"
                    element={
                      <PrivateRoute
                        element={ManageSubscriptionPlans}
                        restrictedToUserId="WiWfYEuXZAe6x9gk58snlCmsIWW2"
                      />
                    }
                  />
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              </Box>
              <Footer sx={{ flexShrink: 0 }} />
            </Box>
          </Router>
        </SubscriptionProvider>
      </AppBarProvider>
    </AuthProvider>
  );
}

const Header = () => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return <AppHeader />;
  } else {
    return <PublicAppBar />;
  }
};

export default App;