import React, { useEffect, useState } from 'react';
import { storage, db } from '../firebase';
import { doc, getDocs, getDoc, collection, updateDoc } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import {
  Link, List, ListItem, ListItemText, MenuItem, Divider, ListSubheader, ListItemAvatar, Avatar, Button, Typography, Container, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Box, Grid, IconButton, Radio, RadioGroup, FormControl, FormControlLabel, useMediaQuery, useTheme 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useAuth } from '../auth';
import SendIcon from '@mui/icons-material/Send';
import Invoice from './Invoice';
import RateSellerDialog from './RateSellerDialog';
import PublicProfile from '../pages/PublicProfile';
import Loading from '../components/Loading';
import BuyerDispute from './BuyerDispute';
import PaymentRequestedDialog from './PaymentRequestedDialog';
import MessageBox from '../components/MessageBox';
import ImageViewerDialog from './ImageViewerDialog';
import SearchSortToolbar from './SearchSortToolbar';  

const BuyerPurchases = () => {
  const { currentUser } = useAuth();
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('date'); 
  const [collapsedSections, setCollapsedSections] = useState({});
  const [allCollapsed, setAllCollapsed] = useState(false); 
  const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [disputeDialogOpen, setDisputeDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [refundReason, setRefundReason] = useState('');
  const [refundPaymentMethod, setRefundPaymentMethod] = useState('');
  const [refundPaymentDetails, setRefundPaymentDetails] = useState({});
  const [selectedShippingOption, setSelectedShippingOption] = useState('');
  const [shippingCost, setShippingCost] = useState(0);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
  const [paymentDetails, setPaymentDetails] = useState('');
  const [buyerAddress, setBuyerAddress] = useState({});
  const [trackingDialogOpen, setTrackingDialogOpen] = useState(false);
  const [rateSellerDialogOpen, setRateSellerDialogOpen] = useState(false);
  const [usernames, setUsernames] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [cancelReason, setCancelReason] = useState('');
  const [open, setOpen] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [paymentFee, setPaymentFee] = useState(0);
  const [viewDisputeDialogOpen, setViewDisputeDialogOpen] = useState(false);
  const [messageBoxOpen, setMessageBoxOpen] = useState(false);
  const [markAsPaidDialogOpen, setMarkAsPaidDialogOpen] = useState(false);
  const [buyerPaymentMessage, setBuyerPaymentMessage] = useState('');
  const [messageBoxContent, setMessageBoxContent] = useState({ title: '', message: '' });
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchPurchases();
  }, [currentUser]);

  useEffect(() => {
    if (selectedShippingOption && selectedPurchase) {
      const shippingOption = selectedPurchase.availableShippingOptions?.find(option => option.method === selectedShippingOption);
      if (shippingOption) {
        setShippingCost(parseFloat(shippingOption.chargePerItem.replace(/[^0-9.-]+/g, "")));
      } else {
        setShippingCost(0);
      }
    }
  }, [selectedShippingOption, selectedPurchase]);

  const handleOpenImageViewer = (purchase) => {
    setSelectedPurchase(purchase);
    setImageDialogOpen(true);
  };

  const handleCloseImageViewer = () => {
    setImageDialogOpen(false);
  };

  useEffect(() => {
    if (selectedPurchase) {
      const subtotal = selectedPurchase.total + shippingCost;

      const optionDetails = selectedPurchase?.paymentOptions?.find(
        (option) => option.method === selectedPaymentOption
      );

      if (optionDetails && optionDetails.details) {
        const feeType = optionDetails.feeType || '%';
        const feeValue = parseFloat(optionDetails.fee || 0);

        let calculatedFee = 0;
        if (feeType === '%') {
          calculatedFee = (feeValue / 100) * subtotal;
        } else {
          calculatedFee = feeValue;
        }

        setPaymentFee(calculatedFee);
        setTotalCost(subtotal + calculatedFee);
      } else {
        setPaymentFee(0);
        setTotalCost(subtotal);
      }
    }
  }, [shippingCost, selectedPurchase, selectedPaymentOption]);

  const groupPurchasesByDate = (purchases) => {
    return purchases.reduce((groups, purchase) => {
      const date = purchase.timestamp?.toDate()
        ? purchase.timestamp.toDate().toLocaleDateString()
        : new Date(purchase.timestamp).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(purchase);
      return groups;
    }, {});
  };

  const sortPurchases = (purchases, sortOption) => {
    return purchases.sort((a, b) => {
      if (sortOption === 'date') {
        return new Date(b.timestamp) - new Date(a.timestamp);
      } else if (sortOption === 'total') {
        return b.total - a.total;
      }
      return 0;
    });
  };

  const filteredPurchases = purchases.filter((purchase) => {
    const sellerName = usernames[purchase.sellerId] || purchase.sellerId;
    const searchInItems = purchase.items?.some((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );

    return (
      sellerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      purchase.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(purchase.total).includes(searchTerm) || // Allow search by total
      searchInItems
    );
  });

  const sortedPurchases = sortPurchases(filteredPurchases, sortOption);
  const groupedPurchases = groupPurchasesByDate(sortedPurchases);

  const toggleCollapse = (date) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  const toggleAllSections = (collapse) => {
    const newState = Object.keys(groupedPurchases).reduce((acc, date) => {
      acc[date] = collapse;
      return acc;
    }, {});
    setCollapsedSections(newState);
    setAllCollapsed(collapse);
  };
  
  

  const fetchPurchases = async () => {
    const purchasesSnapshot = await getDocs(collection(db, 'users', currentUser.uid, 'purchases'));
    const purchasesData = purchasesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const sellerIds = [...new Set(purchasesData.map((purchase) => purchase.sellerId))];
    const fetchedUsernames = {};

    for (const sellerId of sellerIds) {
      const sellerDoc = await getDoc(doc(db, 'users', sellerId));
      if (sellerDoc.exists()) {
        fetchedUsernames[sellerId] = sellerDoc.data().username;
      }
    }

    setUsernames(fetchedUsernames);
    setPurchases(purchasesData);
    setLoading(false);
  };

  const fetchBuyerPreferences = async () => {
    const buyerDocRef = doc(db, 'users', currentUser.uid);
    const buyerDocSnap = await getDoc(buyerDocRef);

    if (buyerDocSnap.exists()) {
      return buyerDocSnap.data().buyerPreferences;
    } else {
      console.error('Buyer preferences not found');
      return null;
    }
  };

  const handleCancelPurchase = async () => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      for (const item of selectedPurchase.items) {
        const listingRef = doc(db, 'sets', item.setId, 'cards', item.docName, 'listings', item.listingId);
        const listingDoc = await getDoc(listingRef);
  
        if (listingDoc.exists()) {
          const listingData = listingDoc.data();
          const listingKey = Object.keys(listingData)[0];
          const listingInfo = listingData[listingKey];
  
          const restoredQuantity = listingInfo.quantity + item.quantity;
          await updateDoc(listingRef, {
            [listingKey]: {
              ...listingInfo,
              quantity: restoredQuantity,
            },
          });
        }
      }
  
      await updateDoc(purchaseRef, {
        status: 'Cancelled',
        cancellationReason: cancelReason,
      });
  
      await updateDoc(saleRef, {
        status: 'Cancelled',
        cancellationReason: cancelReason,
      });
  
      setCancelDialogOpen(false);
      setCancelReason('');
      setMessageBoxContent({
        title: 'Purchase Cancelled',
        message: 'The purchase has been successfully cancelled.',
      });
      setMessageBoxOpen(true);
      fetchPurchases();
    }
  };
  

  const handleOpenDisputeDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setDisputeDialogOpen(true);
  };

  const handleRequestDispute = async (disputeData) => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      const updatedDisputeData = {
        ...disputeData,
        status: 'Dispute Requested',
      };
  
      await updateDoc(purchaseRef, updatedDisputeData);
      await updateDoc(saleRef, updatedDisputeData);
  
      setMessageBoxContent({
        title: 'Dispute Requested',
        message: 'The dispute has been successfully requested.',
      });
      setMessageBoxOpen(true);
      fetchPurchases();
    }
  };
  

  const handleOpenCancelDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setCancelDialogOpen(true);
  };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false);
    setCancelReason('');
  };

  const handleOpenRefundDialog = (purchase) => {
    setSelectedPurchase(purchase);
    setRefundDialogOpen(true);
  };

  const handleRequestRefund = async () => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      await updateDoc(purchaseRef, {
        status: 'Refund Requested',
        refundReason,
        refundPaymentMethod,
        refundPaymentDetails,
      });
  
      await updateDoc(saleRef, {
        status: 'Refund Requested',
        refundReason,
        refundPaymentMethod,
        refundPaymentDetails,
      });
  
      setRefundDialogOpen(false);
      setRefundReason('');
      setRefundPaymentDetails({});
      setMessageBoxContent({
        title: 'Refund Requested',
        message: 'The refund request has been successfully submitted.',
      });
      setMessageBoxOpen(true);
      fetchPurchases();
    }
  };
  

  const handleOpenPaymentDialog = async (purchase) => {
    setSelectedPurchase(purchase);
    setPaymentDialogOpen(true);
  };

  const handleCloseRefundDialog = () => {
    setRefundDialogOpen(false);
    setRefundReason('');
    setRefundPaymentDetails({});
  };

  const handleOpen = (sellerId) => {
    setSelectedUserId(sellerId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUserId(null);
  };

  const handleGetInvoice = async () => {
    const transactionDate = new Date(); 
    if (selectedPurchase) {
      const buyerPreferences = await fetchBuyerPreferences();

      if (buyerPreferences) {
        const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
        const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);

        const sellerDoc = await getDoc(doc(db, 'users', selectedPurchase.sellerId));
        const sellerDetails = sellerDoc.exists() ? sellerDoc.data() : {};

        const updatedPurchaseData = {
          status: 'Invoice Generated',
          buyerPaymentMessage: message,
          buyerAddress: buyerPreferences,
          shippingCost,
          shippingOption: selectedShippingOption,
          paymentOption: { method: selectedPaymentOption, details: paymentDetails },
          totalCost,
          paymentFee,
          transactionDate,
          sellerDetails: {
            firstName: sellerDetails.firstName || 'Seller',
            lastName: sellerDetails.lastName || '',
            email: sellerDetails.email || '',
            phoneNumber: sellerDetails.phoneNumber || ''
          }
        };

        await updateDoc(purchaseRef, updatedPurchaseData);
        await updateDoc(saleRef, updatedPurchaseData);

        await fetchPurchases();

        setSelectedPurchase((prev) => ({ ...prev, ...updatedPurchaseData }));

        setPaymentDialogOpen(false);
        setInvoiceDialogOpen(true);
      } else {
        setMessageBoxContent({
          title: 'Attention Required',
          message: 'Failed to retrieve buyer preferences, please set your preferences in the Profile page.',
        });
        setMessageBoxOpen(true);
      }
    }
  };

  const handleViewDispute = (purchase) => {
    setSelectedPurchase(purchase);
    setViewDisputeDialogOpen(true);
  };

  const handleEscalateDispute = async () => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      await updateDoc(purchaseRef, { status: 'Dispute Escalated' });
      await updateDoc(saleRef, { status: 'Dispute Escalated' });
  
      setMessageBoxContent({
        title: 'Dispute Escalated',
        message: 'The dispute has been escalated to support.',
      });
      setMessageBoxOpen(true);
      setViewDisputeDialogOpen(false);
      fetchPurchases();
    }
  };
  
  
  const handleAcceptResolution = async () => {
    if (selectedPurchase) {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', selectedPurchase.id);
      const saleRef = doc(db, 'users', selectedPurchase.sellerId, 'sales', selectedPurchase.id);
  
      await updateDoc(purchaseRef, { status: 'Received' });
      await updateDoc(saleRef, { status: 'Received' });
  
      setMessageBoxContent({
        title: 'Resolution Accepted',
        message: 'The dispute has been resolved. Please rate the seller to complete the transaction.',
      });
      setMessageBoxOpen(true);
      setViewDisputeDialogOpen(false);
      fetchPurchases();
    }
  };
  

  const handleMarkPaid = (purchase) => {
    console.log('Marking as paid:', purchase);
    setSelectedPurchase(purchase);
    setMarkAsPaidDialogOpen(true);
  };
  
  
  const confirmMarkAsPaid = async (purchaseId, sellerId) => {
    const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', purchaseId);
    const saleRef = doc(db, 'users', sellerId, 'sales', purchaseId);
  
    await updateDoc(purchaseRef, { status: 'Paid', buyerPaymentMessage });
    await updateDoc(saleRef, { status: 'Paid', buyerPaymentMessage });
  
    setMessageBoxContent({
      title: 'Status Updated',
      message: 'The status has been updated to Paid, and the message has been sent to the seller.',
    });
    setMessageBoxOpen(true);
    fetchPurchases();
  };
  
  

  const handleMarkReceived = async (purchaseId, sellerId) => {
    try {
      const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', purchaseId);
      const saleRef = doc(db, 'users', sellerId, 'sales', purchaseId);
  
      await updateDoc(purchaseRef, { status: 'Received' });
      await updateDoc(saleRef, { status: 'Received' });
  
      setMessageBoxContent({
        title: 'Status Updated',
        message: 'The status has been updated to Received.',
      });
      setMessageBoxOpen(true);
      
      // Close the tracking dialog after updating the status
      setTrackingDialogOpen(false);
  
      fetchPurchases();
    } catch (error) {
      console.error("Error marking purchase as received: ", error);
    }
  };
  
  

  const handleMarkRated = async (purchaseId, sellerId) => {
    const purchaseRef = doc(db, 'users', currentUser.uid, 'purchases', purchaseId);
    const saleRef = doc(db, 'users', sellerId, 'sales', purchaseId);
  
    // Delete images upon sale completion
    const saleDoc = await getDoc(saleRef);
    if (saleDoc.exists()) {
      const saleData = saleDoc.data();
      const imageUrls = saleData.imageUrls || [];
      for (const url of imageUrls) {
        const fileRef = ref(storage, url);
        await deleteObject(fileRef);
      }
      await updateDoc(saleRef, { imageUrls: [] });
    }
  
    await updateDoc(purchaseRef, { status: 'Completed' });
    await updateDoc(saleRef, { status: 'Completed' });
  
    setMessageBoxContent({
      title: 'Status Updated',
      message: 'The status has been updated to Completed.',
    });
    setMessageBoxOpen(true);
    fetchPurchases();
    setRateSellerDialogOpen(false);
  };
  
  

  const renderRefundPaymentDetailsFields = () => {
    switch (refundPaymentMethod) {
      case 'Bank Transfer':
        return (
          <Box>
            <TextField
              label="Account Name"
              fullWidth
              value={refundPaymentDetails.accountName || ''}
              onChange={(e) =>
                setRefundPaymentDetails((prev) => ({
                  ...prev,
                  accountName: e.target.value,
                }))
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="Account Number"
              fullWidth
              value={refundPaymentDetails.accountNumber || ''}
              onChange={(e) =>
                setRefundPaymentDetails((prev) => ({
                  ...prev,
                  accountNumber: e.target.value,
                }))
              }
              variant="outlined"
              margin="normal"
            />
            <TextField
              label="BSB"
              fullWidth
              value={refundPaymentDetails.bsb || ''}
              onChange={(e) =>
                setRefundPaymentDetails((prev) => ({
                  ...prev,
                  bsb: e.target.value,
                }))
              }
              variant="outlined"
              margin="normal"
            />
          </Box>
        );
      case 'Paypal':
        return (
          <TextField
            label="Paypal ID"
            fullWidth
            value={refundPaymentDetails.paypalID || ''}
            onChange={(e) =>
              setRefundPaymentDetails((prev) => ({
                ...prev,
                paypalID: e.target.value,
              }))
            }
            variant="outlined"
            margin="normal"
          />
        );
      case 'PayID':
        return (
          <TextField
            label="PayID"
            fullWidth
            value={refundPaymentDetails.payID || ''}
            onChange={(e) =>
              setRefundPaymentDetails((prev) => ({
                ...prev,
                payID: e.target.value,
              }))
            }
            variant="outlined"
            margin="normal"
          />
        );
      case 'Other':
        return (
          <TextField
            label="Other Payment Details"
            fullWidth
            multiline
            rows={4}
            value={refundPaymentDetails.otherDetails || ''}
            onChange={(e) =>
              setRefundPaymentDetails((prev) => ({
                ...prev,
                otherDetails: e.target.value,
              }))
            }
            variant="outlined"
            margin="normal"
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        My Purchases
      </Typography>

      <SearchSortToolbar
        searchTerm={searchTerm}
        sortOption={sortOption}
        onSearchChange={setSearchTerm}
        onSortChange={setSortOption}
        onToggleCollapse={toggleAllSections}
        allCollapsed={allCollapsed}
      />

      {purchases.length === 0 && (
        <Typography variant="body1" align="center">
          No purchases to show.
        </Typography>
      )}
      
      <List>
      {Object.keys(groupedPurchases).map((date) => (
    <React.Fragment key={date}>
      <ListSubheader sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">{date}</Typography>
        <IconButton onClick={() => toggleCollapse(date)}>
          {collapsedSections[date] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      </ListSubheader>

      {/* Check if section is collapsed */}
      {!collapsedSections[date] &&
        groupedPurchases[date].map((purchase) => (
          <ListItem
            key={purchase.id}
            sx={{
              border: "2px solid #0C0A5A",
              borderRadius: 2,
              marginBottom: 2,
              padding: 2,
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4} md={5}>
                <Box>
                  <Typography variant="h6">
                    Seller:
                    <Link
                      onClick={() => handleOpen(purchase.sellerId)}
                      sx={{ ml: 1 }}
                      style={{ cursor: "pointer" }}
                    >
                      <PersonIcon fontSize="small" />{" "}
                      {usernames[purchase.sellerId] || purchase.sellerId}
                    </Link>
                  </Typography>
                  <Typography variant="body1">
                    Total: ${purchase.total.toFixed(2)} - Status: {purchase.status}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    visibility: [
                      "Pending",
                      "Invoice Generated",
                      "Payment Requested",
                    ].includes(purchase.status)
                      ? "visible"
                      : "hidden",
                    height: "auto",
                  }}
                >
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => handleOpenCancelDialog(purchase)}
                    fullWidth
                  >
                    Cancel Purchase
                  </Button>
                </Box>

                {["Paid", "Preparing for Shipping"].includes(purchase.status) && (
                  <Box sx={{ marginTop: 2 }}>
                    <Button
                      variant="text"
                      color="warning"
                      onClick={() => handleOpenRefundDialog(purchase)}
                      fullWidth
                    >
                      Request Refund
                    </Button>
                  </Box>
                )}

                {purchase.status === "Received" && (
                  <Box sx={{ marginTop: 2 }}>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={() => handleOpenDisputeDialog(purchase)}
                      fullWidth
                    >
                      Dispute Purchase
                    </Button>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} sm={4} md={3}>
                <List disablePadding>
                  {purchase.items?.map((item) => (
                    <ListItem
                      key={item.docName}
                      sx={{ paddingLeft: 0, paddingBottom: 1 }}
                    >
                      <ListItemAvatar sx={{ marginRight: 2 }}>
                        <Avatar
                          variant="square"
                          src={item.cardImage || "fallback_image_url"}
                          sx={{
                            height: "80px",
                            width: "60px",
                            objectFit: "contain",
                            borderRadius: 1,
                          }}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "fallback_image_url";
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <>
                            <Typography variant="body1" component="div">
                              {item.cardName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {item.setName} - #{item.collector_number}
                            </Typography>
                          </>
                        }
                        secondary={
                          <>
                            <Typography variant="body2" color="textSecondary">
                              Quantity: {item.staticQuantity}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Price: $
                              {typeof item.staticPrice === "number"
                                ? item.staticPrice.toFixed(2)
                                : item.staticPrice}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Grid item xs={12} sm={4} md={4} sx={{ textAlign: "right" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  {(purchase.status === "Invoice Generated" ||
                    purchase.status === "Paid" ||
                    purchase.status === "Shipped" ||
                    purchase.status === "Completed" ||
                    purchase.status === "Received") && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setSelectedPurchase(purchase);
                        setInvoiceDialogOpen(true);
                      }}
                    >
                      View Invoice
                    </Button>
                  )}

                  {purchase.status !== "Paid" &&
                    purchase.status === "Invoice Generated" && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleMarkPaid(purchase)}
                      >
                        Mark as Paid
                      </Button>
                    )}

                  {purchase.status !== "Invoice Generated" &&
                    purchase.status !== "Paid" &&
                    purchase.status === "Payment Requested" && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setSelectedPurchase(purchase);
                          setPaymentDialogOpen(true);
                        }}
                        endIcon={<SendIcon />}
                      >
                        Get Invoice
                      </Button>
                    )}

                  {purchase.status === "Shipped" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSelectedPurchase(purchase);
                        setTrackingDialogOpen(true);
                      }}
                    >
                      View Tracking
                    </Button>
                  )}

                  {purchase.status === "Dispute Denied" && (
                    <Box sx={{ marginTop: 2 }}>
                      <Button
                        variant="text"
                        color="warning"
                        onClick={() => handleViewDispute(purchase)}
                        fullWidth
                      >
                        View Dispute Details
                      </Button>
                    </Box>
                  )}

                  {purchase.status === "Received" && (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => handleOpenImageViewer(purchase)}
                    >
                      View Card Condition Photos
                    </Button>
                  )}

                  {purchase.status === "Received" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSelectedPurchase(purchase);
                        setRateSellerDialogOpen(true);
                      }}
                    >
                      Rate Seller
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      <Divider />
    </React.Fragment>
  ))}
</List>



      {/* Cancel Purchase Dialog */}
      <Dialog open={cancelDialogOpen} onClose={handleCloseCancelDialog}>
        <DialogTitle>Cancel Purchase</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for Cancellation"
            fullWidth
            multiline
            rows={4}
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <DialogActions>
            <Button onClick={handleCloseCancelDialog} color="secondary">
              Close
            </Button>
            <Button onClick={handleCancelPurchase} color="primary">
              Confirm Cancellation
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {/* Request Refund Dialog */}
      <Dialog open={refundDialogOpen} onClose={handleCloseRefundDialog}>
        <DialogTitle>Request Refund</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for Refund"
            fullWidth
            multiline
            rows={4}
            value={refundReason}
            onChange={(e) => setRefundReason(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <FormControl component="fieldset" fullWidth margin="normal">
            <Typography variant="subtitle1">Refund Payment Method</Typography>
            <RadioGroup
              value={refundPaymentMethod}
              onChange={(e) => setRefundPaymentMethod(e.target.value)}
            >
              <FormControlLabel
                value="Bank Transfer"
                control={<Radio />}
                label="Bank Transfer"
              />
              <FormControlLabel
                value="Paypal"
                control={<Radio />}
                label="Paypal"
              />
              <FormControlLabel
                value="PayID"
                control={<Radio />}
                label="PayID"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          {renderRefundPaymentDetailsFields()}
          <DialogActions>
            <Button onClick={handleCloseRefundDialog} color="secondary">
              Close
            </Button>
            <Button onClick={handleRequestRefund} color="primary">
              Confirm Refund Request
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>


 {/* View Dispute Details Dialog */}
<Dialog open={viewDisputeDialogOpen} onClose={() => setViewDisputeDialogOpen(false)}>
  <DialogTitle>Dispute Details</DialogTitle>
  <DialogContent>
    <Typography variant="subtitle1">Reason for Dispute</Typography>
    <Typography variant="body2">{selectedPurchase?.disputeReason || 'No reason provided.'}</Typography>
    <Typography variant="subtitle1" gutterBottom>Seller Response</Typography>
    <Typography variant="body2">{selectedPurchase?.sellerResponse || 'No response provided.'}</Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setViewDisputeDialogOpen(false)} color="secondary">
      Close
    </Button>
    <Button onClick={handleEscalateDispute} color="warning">
      Escalate
    </Button>
    <Button onClick={handleAcceptResolution} color="primary">
      Accept Resolution
    </Button>
  </DialogActions>
</Dialog>



      {/* Dispute Purchase Dialog */}
      <BuyerDispute
        open={disputeDialogOpen}
        onClose={() => setDisputeDialogOpen(false)}
        onSubmit={handleRequestDispute}
        purchase={selectedPurchase}
      />

<PaymentRequestedDialog
        open={paymentDialogOpen}
        onClose={() => setPaymentDialogOpen(false)}
        purchase={selectedPurchase}
        buyerAddress={buyerAddress}
        setBuyerAddress={setBuyerAddress}
        fetchBuyerPreferences={fetchBuyerPreferences}
        handleGetInvoice={handleGetInvoice}
        message={message}
        setMessage={setMessage}
        selectedShippingOption={selectedShippingOption}
        setSelectedShippingOption={setSelectedShippingOption}
        selectedPaymentOption={selectedPaymentOption}
        setSelectedPaymentOption={setSelectedPaymentOption}
        paymentDetails={paymentDetails}
        setPaymentDetails={setPaymentDetails}
        paymentFee={paymentFee}
        totalCost={totalCost}
        handleCancelPurchase={handleCancelPurchase}
      />

      <Dialog
        open={invoiceDialogOpen}
        onClose={() => setInvoiceDialogOpen(false)}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogContent>
          <Invoice
            sale={selectedPurchase}
            onClose={() => setInvoiceDialogOpen(false)}
            shippingOption={selectedPurchase?.shippingOption}
            paymentOption={selectedPurchase?.paymentOption}
            totalCost={totalCost}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={trackingDialogOpen}
        onClose={() => setTrackingDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Tracking Information</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            Shipping Company: {selectedPurchase?.shippingCompany || "N/A"}
          </Typography>
          <Typography variant="subtitle1">
            Tracking Number: {selectedPurchase?.trackingNumber || "N/A"}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setTrackingDialogOpen(false)}
            color="secondary"
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleMarkReceived(selectedPurchase.id, selectedPurchase.sellerId)
            }
          >
            Mark as Received
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedUserId && <PublicProfile userId={selectedUserId} />}
        </DialogContent>
      </Dialog>

      <Dialog open={markAsPaidDialogOpen} onClose={() => setMarkAsPaidDialogOpen(false)}>
  <DialogTitle>Mark as Paid</DialogTitle>
  <DialogContent>
  <Typography variant="subtitle1">
            Total Owing: ${selectedPurchase?.totalCost || "N/A"}
        </Typography>
    <Typography variant="body1" gutterBottom>
      Do you want to send a message to the seller?
    </Typography>
    <TextField
      label="Message to Seller (Optional)"
      fullWidth
      multiline
      rows={4}
      value={buyerPaymentMessage}
      onChange={(e) => setBuyerPaymentMessage(e.target.value)}
      variant="outlined"
      margin="normal"
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setMarkAsPaidDialogOpen(false)} color="secondary">
      Cancel
    </Button>
    <Button
      onClick={async () => {
        await confirmMarkAsPaid(selectedPurchase.id, selectedPurchase.sellerId);
        setMarkAsPaidDialogOpen(false);
      }}
      color="primary"
    >
      Confirm Payment
    </Button>
  </DialogActions>
</Dialog>


      <RateSellerDialog
        open={rateSellerDialogOpen}
        onClose={() =>
          handleMarkRated(selectedPurchase.id, selectedPurchase.sellerId)
        }
        sale={selectedPurchase}
      />
      {selectedPurchase && (
        <ImageViewerDialog
          open={imageDialogOpen}
          onClose={handleCloseImageViewer}
          saleId={selectedPurchase.id}
          sellerId={selectedPurchase.sellerId}
        />
      )}
      <MessageBox 
  open={messageBoxOpen} 
  title={messageBoxContent.title} 
  message={messageBoxContent.message} 
  onClose={() => setMessageBoxOpen(false)} 
/>

    </Container>
  );
};

export default BuyerPurchases;
