import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, ListItemIcon } from '@mui/material';
import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { inkColorMap, rarityIcons } from './constants';

const CardList = ({ cards, handleCardClick, convertPrice }) => {
  return (
    <List>
      {cards.map((card) => (
        <ListItem key={card.docName} sx={{ border: "2px solid #0C0A5A" }}>
          <ListItemAvatar sx={{ width: 100 }}>
            <Avatar
              className={card.type === "Foil" ? "foil-avatar" : ""}
              variant="square"
              src={card.image_uris?.digital?.small || card.image_uris?.digital?.large}
              sx={{
                height: "20%",
                width: "80%",
                objectFit: "contain",
                border: `4px solid ${inkColorMap[card.ink] || "#F2D18B"}`,
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "fallback_image_url";
              }}
            />
            {card.type === "Foil" && (
              <Avatar
                variant="square"
                sx={{
                  width: 76,
                  height: 10,
                  bgcolor: "#ffd700",
                  border: "2px solid #000000",
                  color: "#000000",
                  bottom: 18,
                  left: 4,
                }}
              >
                <Typography variant="h6" sx={{ fontSize: "10px" }}>
                  COLD FOIL
                </Typography>
              </Avatar>
            )}
          </ListItemAvatar>
          <Stack spacing={1}>
            <ListItemIcon>
              <Avatar variant="square" sx={{ bgcolor: "#0C0A5A" }}>
                {card.collector_number}
              </Avatar>
            </ListItemIcon>
            <ListItemIcon>
              <Avatar variant="square" sx={{ bgcolor: "#0C0A5A" }}>
                <img
                  src={rarityIcons[card.rarity]}
                  alt={card.rarity}
                  style={{ width: 24, height: 24 }}
                />
              </Avatar>
            </ListItemIcon>
          </Stack>
          <ListItemText
            primary={<Typography variant="body1">{card.name}</Typography>}
            secondary={
              card.version && (
                <Typography variant="body2" color="textSecondary">
                  {card.version}
                </Typography>
              )
            }
          />
          <ListItemSecondaryAction>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Chip
                    sx={{
                      height: "auto",
                      borderRadius: "4px", 
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                      },
                      bgcolor: "secondary.main",
                      color: "white",
                    }}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span>Listings</span>
                        {card.listingsCount || 0}
                      </Box>
                    }
                    onClick={() =>
                      handleCardClick({
                        setId: card.setId,
                        docName: card.docName,
                      })
                    }
                    clickable
                  />
                </Box>
                <Chip
                  clickable
                  component="a"
                  href={`https://www.tcgplayer.com/product/${card.tcgplayer_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                    borderRadius: "4px", 
                    bgcolor: "green",
                    color: "white",
                  }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <span>TCGP Price</span>
                      {convertPrice(card.price || 0)}
                    </Box>
                  }
                />
                <Button
                  size="small"
                  onClick={() =>
                    handleCardClick({
                      setId: card.setId,
                      docName: card.docName,
                    })
                  }
                >
                  Card Info
                </Button>
              </Box>
            </Box>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default CardList;
