import React, { useEffect, useMemo, useCallback, useReducer, useState } from 'react';
import { useAuth } from '../auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FeatureControl from '../context/FeatureControl';
import {
  Container,
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  AppBar,
  Toolbar,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useAppBarContext } from '../components/AppBarContext';
import Filters from './Filters';
import { initialState, reducer } from './reducer';
import { currencySymbols } from './constants';
import InventoryCardList from './InventoryCardList';
import InventoryGridList from './InventoryGridList';
import ListingModal from './ListingModal';
import ImportExportComponent from './ImportExportComponent';
import FacebookListingCreator from '../pages/FacebookListingCreator';
import Loading from '../components/Loading';
import ImportExportListingsComponent from './ImportExportListingsComponent';
import withAnalytics from '../hoc/withAnalytics';
import { backgroundScroll } from '../utils/BackgroundScroll';

const InventoryManagement = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [exportImportOpen, setExportImportOpen] = useState(false);
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openFacebookListingDialog, setOpenFacebookListingDialog] = useState(false);
  const [isFacebookListingMode, setIsFacebookListingMode] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [glimmerScanOpen, setGlimmerScanOpen] = useState(false);
  const [setsWithListingsCount, setSetsWithListingsCount] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (modalOpen || openFacebookListingDialog || glimmerScanOpen || exportImportOpen) {
      backgroundScroll.disableScroll(true);  // Disable scroll when any dialog is open
    } else {
      backgroundScroll.disableScroll(false); // Re-enable scroll when all dialogs are closed
    }

    return () => {
      backgroundScroll.disableScroll(false);  // Cleanup on component unmount
    };
  }, [modalOpen, openFacebookListingDialog, glimmerScanOpen]);

  // Open the Glimmer Scan import dialog
  const handleOpenGlimmerScan = () => {
    setGlimmerScanOpen(true);
  };

  // Close the Glimmer Scan import dialog
  const handleCloseGlimmerScan = () => {
    setGlimmerScanOpen(false);
  };

  const toggleFacebookListingMode = () => {
    setIsFacebookListingMode(prevMode => !prevMode);
  };

  const updateSelectedItems = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  };

  const getCurrencySymbol = useCallback(() => {
    return currencySymbols[selectedCountry] || '$';
  }, [selectedCountry]);

  const convertPrice = useCallback((usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${currencySymbols[selectedCountry]}${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  }, [exchangeRates, selectedCountry]);

  const getInitialPrice = useCallback((price) => {
    if (!exchangeRates[selectedCountry]) return price;
    return parseFloat((price * exchangeRates[selectedCountry]).toFixed(2));
  }, [exchangeRates, selectedCountry]);

  const calculateTotalListingCount = useCallback(() => {
    return state.cards.reduce((totalCount, card) => {
      const userListings = card.listings[currentUser.uid] || [];
      return totalCount + userListings.length;
    }, 0);
  }, [state.cards, currentUser.uid]);
  

  useEffect(() => {
    // Function to calculate total listings count for each set
    const fetchListingCounts = async () => {
      try {
        const setsSnapshot = await getDocs(collection(db, 'sets'));
        const setsData = setsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
        const fetchListingsForSet = async (setId) => {
          const cardsSnapshot = await getDocs(collection(db, 'sets', setId, 'cards'));
          let listingsCount = 0;
          await Promise.all(cardsSnapshot.docs.map(async (cardDoc) => {
            const listingsSnapshot = await getDocs(collection(db, 'sets', setId, 'cards', cardDoc.id, 'listings'));
    
            // Only count listings that belong to the current user
            listingsSnapshot.docs.forEach((listingDoc) => {
              if (listingDoc.id === currentUser.uid) {
                listingsCount += 1;
              }
            });
          }));
          return listingsCount;
        };
    
        // Map through sets and calculate total listings for each set (only for the current user)
        const setsWithListingsCount = await Promise.all(
          setsData.map(async (set) => {
            const totalListings = await fetchListingsForSet(set.id);
            return { ...set, totalListings };
          })
        );
    
        setSetsWithListingsCount(setsWithListingsCount);
        setLoading(false); // Finished loading after fetching data
      } catch (error) {
        console.error('Error fetching sets and listings:', error);
      }
    };
    

    fetchListingCounts();
  }, [currentUser]);

  useEffect(() => {
    const fetchUserVerificationStatus = async () => {
      dispatch({ type: 'SET_VERIFIED', payload: true });
      dispatch({ type: 'SET_LOADING', payload: false });
    };

    const fetchSetsAndCards = async () => {
      try {
        const setsSnapshot = await getDocs(collection(db, 'sets'));
        const setsData = setsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const selectedSet = setsData.find(set => set.code === "1")?.id || setsData[0]?.id;

        const fetchCardsForSet = async (setId) => {
          const cardsSnapshot = await getDocs(collection(db, 'sets', setId, 'cards'));
          const cardsData = await Promise.all(cardsSnapshot.docs.map(async (cardDoc) => {
            const cardData = { docId: cardDoc.id, setId, ...cardDoc.data() };

            const listingsSnapshot = await getDocs(collection(db, 'sets', setId, 'cards', cardDoc.id, 'listings'));
            const listingsData = listingsSnapshot.docs.reduce((acc, listingDoc) => {
              if (listingDoc.id === currentUser.uid) {
                const data = listingDoc.data();
                acc[listingDoc.id] = Object.entries(data).map(([condition, listing]) => ({
                  condition,
                  ...listing,
                }));
              }
              return acc;
            }, {});

            cardData.listingsCount = Object.keys(listingsData).length;
            cardData.listings = listingsData;

            return cardData;
          }));
          return cardsData;
        };

        const cardsData = selectedSet ? await fetchCardsForSet(selectedSet) : [];

        dispatch({
          type: 'SET_DATA',
          payload: { sets: setsData, selectedSet, cards: cardsData, loading: false }
        });
      } catch (error) {
        console.error('Error fetching sets and cards:', error);
      }
    };

    fetchUserVerificationStatus();
    fetchSetsAndCards();
  }, [getInitialPrice, currentUser]);

  const handleSetChange = useCallback((event, value) => {
    dispatch({ type: 'SET_SELECTED_SET', payload: value });
    if (value) fetchCardsForSet(value);
  }, []);

  const fetchCardsForSet = async (setId) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const cardsSnapshot = await getDocs(collection(db, 'sets', setId, 'cards'));
      const cardsData = await Promise.all(cardsSnapshot.docs.map(async (cardDoc) => {
        const listingsSnapshot = await getDocs(collection(db, 'sets', setId, 'cards', cardDoc.id, 'listings'));
        const listingsData = listingsSnapshot.docs.reduce((acc, listingDoc) => {
          if (listingDoc.id === currentUser.uid) {
            const data = listingDoc.data();
            acc[listingDoc.id] = Object.entries(data).map(([condition, listing]) => ({
              condition,
              ...listing,
            }));
          }
          return acc;
        }, {});
        return { docId: cardDoc.id, setId, listings: listingsData, listingsCount: Object.keys(listingsData).length, ...cardDoc.data() };
      }));
      dispatch({ type: 'UPDATE_CARDS', payload: cardsData });
      dispatch({ type: 'SET_LOADING', payload: false });
    } catch (error) {
      console.error('Error fetching cards and listings:', error);
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const handleRarityChange = useCallback((event, value) => {
    dispatch({ type: 'SET_SELECTED_RARITIES', payload: value });
  }, []);

  const handleInkChange = useCallback((event, value) => {
    dispatch({ type: 'SET_SELECTED_INKS', payload: value });
  }, []);

  const handleCardTypeChange = useCallback((event, value) => {
    dispatch({ type: 'SET_SELECTED_CARD_TYPES', payload: value });
  }, []);

  const handleListingsChange = (event, value) => {
    dispatch({ type: 'SET_SHOW_WITH_LISTINGS', payload: value });
  };

  const handleSearchChange = (event) => {
    dispatch({ type: 'SET_SEARCH_TERM', payload: event.target.value });
  };

  const debouncedHandleSearchChange = useMemo(() => debounce(handleSearchChange, 300), []);

  useEffect(() => {
    return () => {
      debouncedHandleSearchChange.cancel();
    };
  }, [debouncedHandleSearchChange]);

  const handleClearSearch = () => {
    dispatch({ type: 'SET_SEARCH_TERM', payload: '' });
  };

  const handleSortChange = (event) => {
    dispatch({ type: 'SET_SORT_BY', payload: event.target.value });
  };

  const handleViewToggle = () => {
    dispatch({ type: 'SET_VIEW', payload: state.view === 'grid' ? 'list' : 'grid' });
  };

  const openListingModal = (card) => {
    const currentListingCount = calculateTotalListingCount(); // Get the total listing count for the user
    setSelectedCard({ ...card, currentListingCount });
    setModalOpen(true);
  };
  

  const closeListingModal = () => {
    setSelectedCard(null);
    setModalOpen(false);
  };

  const updateListings = (docId, listingData) => {
    dispatch({
      type: 'UPDATE_CARDS',
      payload: state.cards.map(card =>
        card.docId === docId ? { ...card, listings: listingData } : card
      ),
    });
  };

  const filteredCards = useMemo(() => state.cards.filter(card =>
    (!state.selectedSet || state.selectedSet === card.setId) &&
    (!state.selectedRarities.length || state.selectedRarities.includes(card.rarity)) &&
    (!state.selectedInks.length || state.selectedInks.includes(card.ink)) &&
    (!state.selectedCardTypes.length || state.selectedCardTypes.includes(card.type)) &&
    (!state.searchTerm || card.name?.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
      card.version?.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
      card.collector_number?.toLowerCase().includes(state.searchTerm.toLowerCase()) ||
      card.username?.toLowerCase().includes(state.searchTerm.toLowerCase())) &&  // Add this line for username search
    (!state.showWithListings || card.listingsCount > 0)
  ), [state.cards, state.selectedSet, state.selectedRarities, state.selectedInks, state.selectedCardTypes, state.searchTerm, state.showWithListings]);

  const sortedCards = useMemo(() => filteredCards.sort((a, b) => {
    if (state.sortBy === 'az') return a.name.localeCompare(b.name);
    if (state.sortBy === 'za') return b.name.localeCompare(a.name);
    if (state.sortBy === 'price_high') return parseFloat(b.price || 0) - parseFloat(a.price || 0);
    if (state.sortBy === 'price_low') return parseFloat(a.price || 0) - parseFloat(b.price || 0);
    if (state.sortBy === 'card_low') return parseFloat(a.collector_number || 0) - parseFloat(b.collector_number || 0);
    if (state.sortBy === 'card_high') return parseFloat(b.collector_number || 0) - parseFloat(a.collector_number || 0);
    return 0;
  }), [filteredCards, state.sortBy]);

  // Scroll to top button visibility logic
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!state.isVerified) {
    return (
      <div>
        <p>Your account is not verified. Please verify your account before adding a listing.</p>
        <Button onClick={() => navigate('/')}>Return to Home</Button>
      </div>
    );
  }

  return (
    <FeatureControl requiredFeatures={["Verified Account"]}>
        <AppBar
          position="static"
          sx={{ bgcolor: "white", color: "primary.main" }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: isMobile ? "column" : "row", // Stack vertically on mobile
              gap: isMobile ? 2 : 0,
              padding: isMobile ? "10px" : "20px",
            }}
          >
            
            <Box
              sx={{
                width: isMobile ? "100%" : "auto",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={toggleFacebookListingMode}
                sx={{
                  color: "primary.main",
                  width: isMobile ? "100%" : "auto",
                }} // Full width on mobile
              >
                {isFacebookListingMode
                  ? "Back to Inventory"
                  : "Create Facebook Post"}
              </Button>
            </Box>
            
            {/* Import/Export Button - hidden in Facebook Listing Mode */}
            {!isFacebookListingMode && (
              <>
              <Box
                sx={{
                  width: isMobile ? "100%" : "auto",
                  textAlign: isMobile ? "center" : "right",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  aria-label="import-export"
                  onClick={() => setExportImportOpen(true)}
                  sx={{
                    color: "primary.main",
                    width: isMobile ? "100%" : "auto",
                  }}
                >
                  Import/Export Listings
                </Button>
                </Box>
                <Box
                sx={{
                  width: isMobile ? "100%" : "auto",
                  textAlign: isMobile ? "center" : "right",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenGlimmerScan}
                  sx={{
                    color: "primary.main",
                    width: isMobile ? "100%" : "auto",
                  }}
                >
                  Import from Glimmer Scan
                </Button>
              </Box>
              </>
            )}
          </Toolbar>
        </AppBar>

        <Container sx={{ paddingTop: "40px" }}>
          <Typography variant="h5" sx={{ ml: 2, textAlign: "center" }}>
            {isFacebookListingMode
              ? "Create Facebook Post"
              : "Inventory Management"}
          </Typography>
          <Typography variant="body" gutterBottom sx={{ mt: 2, textAlign: "center"  }}>
            {isFacebookListingMode
              ? "Select cards from your listings to generate a post and images you can use on Facebook(or anywhere else)."
              : "Any items you add here will be available for purchase on the marketplace."}
          </Typography>

          <ImportExportComponent
            open={exportImportOpen}
            onClose={() => setExportImportOpen(false)}
            fullScreen={isMobile}
          />
          <ImportExportListingsComponent
            open={glimmerScanOpen}
            onClose={handleCloseGlimmerScan}
            fullScreen={isMobile}
          />
          <Filters
            state={state}
            handleSetChange={handleSetChange}
            handleRarityChange={handleRarityChange}
            handleInkChange={handleInkChange}
            handleCardTypeChange={handleCardTypeChange}
            handleSearchChange={debouncedHandleSearchChange}
            handleClearSearch={handleClearSearch}
            handleSortChange={handleSortChange}
            handleViewToggle={handleViewToggle}
            handleListingsChange={handleListingsChange}
            listingsCount={setsWithListingsCount}
          />
          {state.loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Loading />
            </Box>
          ) : sortedCards.length === 0 ? ( // Check if there are no cards after filtering
            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              No items to show. Try adjusting the filters.
            </Typography>
          ) : isFacebookListingMode ? (
            <FacebookListingCreator
              cards={state.cards.filter((card) => card.listingsCount > 0)}
              currentUser={currentUser}
              convertPrice={convertPrice}
              selectedItems={selectedItems}
              setSelectedItems={updateSelectedItems}
            />
          ) : state.view === "grid" ? (
            <InventoryGridList
              convertPrice={convertPrice}
              cards={sortedCards}
              openListingModal={openListingModal}
              currentUser={currentUser}
              updateListings={updateListings}
            />
          ) : (
            <InventoryCardList
              convertPrice={convertPrice}
              cards={sortedCards}
              openListingModal={openListingModal}
              currentUser={currentUser}
              updateListings={updateListings}
            />
          )}

          {!isFacebookListingMode && showScrollTop && (
            <Fab
              variant="extended"
              color="grey"
              aria-label="scroll to top"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              sx={{
                position: "fixed",
                bottom: 10, // Adjust this value according to your footer height
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1000,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <ArrowUpwardIcon />
            </Fab>
          )}

          {selectedCard && (
            <ListingModal
              open={modalOpen}
              onClose={closeListingModal}
              card={selectedCard}
              currentUser={currentUser}
              updateListings={updateListings}
              tcgPrice={selectedCard.price}
              convertPrice={convertPrice}
              currentListingCount={selectedCard.currentListingCount}
              fullScreen={isMobile}
            />
          )}
          {/* Facebook Listing Creator Dialog */}
          <Dialog
            open={openFacebookListingDialog}
            onClose={() => setOpenFacebookListingDialog(false)}
            maxWidth="md"
            fullWidth
            fullScreen={isMobile}
          >
            <DialogTitle>Create Facebook Post</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <Typography variant="body1">
                  Select cards from your inventory to create a Facebook post.
                  Images will show maximum 6 cards per image and there is a
                  limit of 60 cards per post(10 images).
                </Typography>
              </DialogContentText>
              <FacebookListingCreator
                cards={state.cards.filter((card) => card.listingsCount > 0)}
                currentUser={currentUser}
                convertPrice={convertPrice}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenFacebookListingDialog(false)}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
    </FeatureControl>
  );
};

export default withAnalytics(InventoryManagement);
